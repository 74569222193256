import styled from '@emotion/styled';
import { Empty, Button } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'hooks';

export const DeliveryZoneDropdownEmptyState = (): JSX.Element => {
  const { t } = useTranslation('deliveriesForm');
  const navigate = useNavigate();

  return (
    <EmptyStateWrapper>
      <Empty />
      <Button
        type="link"
        onClick={() => navigate(`./${Routes.DELIVERY_ZONES}`)}
      >
        {t('zone_link')}
      </Button>
    </EmptyStateWrapper>
  );
};

const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .ant-empty-description {
    display: none;
  }
`;
