import { useNavigate, useLocation } from 'react-router-dom';

type Params = {
  start?: string;
  end?: string;
  location?: string;
};

type UseQueryParams = {
  setParams: (params: Params) => void;
  query: URLSearchParams;
};

export const useQueryParams = (): UseQueryParams => {
  const navigate = useNavigate();
  const routerLocation = useLocation();
  const query = new URLSearchParams(routerLocation.search);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setParams = (newParams: Record<string, any>) => {
    navigate({
      pathname: routerLocation.pathname,
      search: `?${new URLSearchParams({
        ...newParams,
      }).toString()}`,
    });
  };

  return {
    setParams,
    query,
  };
};
