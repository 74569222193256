import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { CircleCheckmarkSolid } from '@robinpowered/icons';
import { Typography, theme } from '@robinpowered/ui-kit';

export const DeliveryRejected = (): JSX.Element => {
  const { t } = useTranslation('deliveryConfirmation');
  const { token } = theme.useToken();

  return (
    <MessageWrapper>
      <CircleCheckmarkSolid color={token.colorSuccess} size={72} />
      <StyledHeading>{t('rejection_heading')}</StyledHeading>
      <StyledBody size="base">{t('rejection_body')}</StyledBody>
    </MessageWrapper>
  );
};

const StyledHeading = styled(Typography.Title)`
  display: block;
  margin-top: var(--Space-Margin-paddingLG, 24px);
  color: var(--Components-Result-Global-colorTextHeading, rgba(0, 0, 0, 0.95));
  text-align: center;
  font-size: var(--Components-Result-Global-fontSizeHeading3, 22px);
  font-weight: var(--Typography-Font-Weight-fontWeightStrong, 500);
  line-height: var(--Components-Result-Global-lineHeightHeading3, 28px);
`;

const StyledBody = styled(Typography.Text)`
  display: block;
  margin-top: var(--Space-Margin-marginXS, 8px);
  color: var(
    --Components-Result-Global-colorTextDescription,
    rgba(0, 0, 0, 0.55)
  );
  font-size: var(--Components-Result-Global-fontSize, 14px);
  font-weight: var(--Typography-Font-Weight-fontWeightNormal, 400);
  line-height: var(--Components-Result-Global-lineHeight, 22px);
  text-align: center;
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
