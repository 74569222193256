import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

export enum Routes {
  DELIVERIES = 'deliveries',
  DELIVERY_ZONES = 'delivery-zones',
}

const getLastRoute = (pathname: string) => pathname.split('/').pop();

export const useSidebarNavItems = () => {
  const { t } = useTranslation('deliveriesPage');
  const { orgSlug } = useParams<{ orgSlug: string }>();
  const navigate = useNavigate();
  const routerLocation = useLocation();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const navItems = [
    {
      key: Routes.DELIVERIES,
      label: t('sidebar.title'),
      onClick: () => navigate(`/${orgSlug}/${Routes.DELIVERIES}`),
    },
    {
      key: t('sidebar.configurations.title'),
      label: t('sidebar.configurations.title'),
      children: [
        {
          key: Routes.DELIVERY_ZONES,
          label: t('sidebar.configurations.delivery_zones'),
          onClick: () =>
            navigate(
              `/${orgSlug}/${Routes.DELIVERIES}/${Routes.DELIVERY_ZONES}`
            ),
        },
      ],
    },
  ];

  // Set selected keys based on the current route. These keys are used by Ant
  // design to determine the active nav item in the sidebar.
  useEffect(() => {
    const route = getLastRoute(routerLocation.pathname);

    if (Object.values(Routes).includes(route as Routes)) {
      setSelectedKeys([route as Routes]);
    }
  }, [routerLocation.pathname]);

  return { navItems, selectedKeys };
};
