import { useMutation, gql, MutationTuple } from '@apollo/client';
import {
  UpdateDeliveryStatusByMagicLinkMutation,
  UpdateDeliveryStatusByMagicLinkMutationVariables,
} from 'generated/graphql';

export const UPDATE_DELIVERY_STATUS = gql`
  mutation UpdateDeliveryStatusByMagicLink(
    $magicLink: String!
    $status: DeliveryStatus!
  ) {
    updateDeliveryStatusByMagicLink(magicLink: $magicLink, status: $status) {
      id
    }
  }
`;

export const useUpdateDeliveryStatusByMagicLink = (): MutationTuple<
  UpdateDeliveryStatusByMagicLinkMutation,
  UpdateDeliveryStatusByMagicLinkMutationVariables
> => {
  return useMutation<
    UpdateDeliveryStatusByMagicLinkMutation,
    UpdateDeliveryStatusByMagicLinkMutationVariables
  >(UPDATE_DELIVERY_STATUS);
};
