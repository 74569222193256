import common from './common.json';
import deliveryConfirmation from './deliveryConfirmation.json';
import deliveryDetails from './deliveryDetails.json';
import deliveriesForm from './deliveriesForm.json';
import deliveriesPage from './deliveriesPage.json';
import deliveriesTable from './deliveriesTable.json';
import deliveryZonesPage from './deliveryZonesPage.json';
import deliveryZonesTable from './deliveryZonesTable.json';

export const fr_ca = {
  common,
  deliveryConfirmation,
  deliveryDetails,
  deliveriesForm,
  deliveriesPage,
  deliveriesTable,
  deliveryZonesPage,
  deliveryZonesTable,
};
