import { DeliveryStatus } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { Tag, theme } from '@robinpowered/ui-kit';
import { TFunction } from 'i18next';
import styled from '@emotion/styled';

type DeliveryStatusTagProps = {
  status: DeliveryStatus;
};

export const getBorderColor = (status: string) => {
  const { token } = theme.useToken();

  switch (status) {
    case DeliveryStatus.PickedUp:
      return `colorSuccessBorder, ${token.colorSuccessBorder}`;
    case DeliveryStatus.UnknownRecipient:
      return `colorWarningBorder, ${token.colorWarningBorder}`;
    case DeliveryStatus.WrongRecipient:
      return `colorErrorBorder, ${token.colorErrorBorder}`;
    default:
      return `colorInfoBorder, ${token.colorInfoBorder}`;
  }
};

export const getTagColor = (status: string) => {
  const { token } = theme.useToken();

  switch (status) {
    case DeliveryStatus.PickedUp:
      return token.colorSuccessBg;
    case DeliveryStatus.UnknownRecipient:
      return token.colorWarningBg;
    case DeliveryStatus.WrongRecipient:
      return token.colorErrorBg;
    default:
      return token.colorInfoBg;
  }
};

export const getStatusDisplay = (
  status: DeliveryStatus,
  t: TFunction
): string => {
  switch (status) {
    case DeliveryStatus.PickedUp:
      return t('deliveryStatus.pickedUp');
    case DeliveryStatus.UnknownRecipient:
      return t('deliveryStatus.unknownRecipient');
    case DeliveryStatus.WrongRecipient:
      return t('deliveryStatus.wrongRecipient');
    case DeliveryStatus.Pending:
      return t('deliveryStatus.pending');
    default:
      return t('deliveryStatus.pending');
  }
};

export const DeliveryStatusTag = ({ status }: DeliveryStatusTagProps) => {
  const { t } = useTranslation('deliveriesTable');
  return (
    <StyledTag color={getTagColor(status)} border={getBorderColor(status)}>
      {getStatusDisplay(status, t)}
    </StyledTag>
  );
};

export const StyledTag = styled(Tag)<{ border: string }>`
  color: var(--Colors-Neutral-Text-colorText) !important;
  ${({ border }) =>
    border &&
    `
    border-color: var(--Components-Tag-Global-${border}) !important;`}
`;
