import { Colors } from '@robinpowered/design-system';
import {
  Button,
  Typography,
  Input,
  Select,
  Modal,
  message,
  Drawer,
  ConfigProvider,
} from '@robinpowered/ui-kit';
import { InfoSolid } from '@robinpowered/icons';
import styled from '@emotion/styled';
import { Sentry } from 'lib/sentry';
import { useTranslation } from 'react-i18next';
import { useDeliveryFormContext } from '../contexts/DeliveryFormContext';
import { SelectedUser } from 'components/SelectedUser';
import { UserSelect } from 'components';
import { useState } from 'react';
import { DELIVERIES_QUERY, defaultOrderBy } from '../hooks';
import {
  useDeliveriesTableContext,
  useDeliveryZonesContext,
} from '../contexts';
import { DeliveryZoneDropdownEmptyState } from './DeliveryZoneDropdownEmptyState';

type DeliveriesCreationDrawerProps = {
  showNewDeliveryDrawer: boolean;
  setShowNewDeliveryDrawer: (open: boolean) => void;
};

export const DeliveriesCreationDrawer = ({
  showNewDeliveryDrawer,
  setShowNewDeliveryDrawer,
}: DeliveriesCreationDrawerProps) => {
  const { t } = useTranslation('deliveriesForm');
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const { deliveryZones } = useDeliveryZonesContext();
  const {
    recipient,
    carrier,
    recipientName,
    recipientEmail,
    deliveryZoneId,
    deliveryNotes,
    isFormValid,
    deliveryZoneIdError,
    recipientEmailError,
    recipientNameError,
    showErrors,
    hasFormChanged,
    deliveryCreationLoading,
    setCarrier,
    setShowErrors,
    setRecipient,
    setRecipientName,
    setRecipientEmail,
    setDeliveryZoneId,
    setDeliveryNotes,
    clearValues,
    createDelivery,
  } = useDeliveryFormContext();
  const {
    filterOptions,
    orderByOptions = {},
    currentPage = 1,
    itemsPerPage = 100,
  } = useDeliveriesTableContext();
  const [messageApi, contextHolder] = message.useMessage();

  const handleCloseDrawer = () => {
    if (hasFormChanged) {
      setShowCancelDialog(true);
    } else {
      closeDrawer();
    }
  };

  const closeDrawer = () => {
    setShowCancelDialog(false);
    setShowNewDeliveryDrawer(false);
    clearValues();
  };

  const handleSubmitForm = () => {
    setShowErrors(true);

    if (isFormValid) {
      const variables = {
        input: {
          deliveryZoneId: deliveryZoneId as string,
          recipient:
            recipientName && recipientEmail
              ? {
                  name: recipientName,
                  email: recipientEmail,
                  userId: recipient?.id,
                }
              : null,
          carrier: carrier,
          additionalNotes: deliveryNotes,
        },
      };

      createDelivery({
        variables,
        onCompleted: async () => {
          closeDrawer();
          await messageApi.success(t('create_success'));
        },
        // Note: these variables must match the deliveries query variables exactly in
        // order for the apollo cache to be updated correctly and replace the stale data
        refetchQueries: [
          {
            query: DELIVERIES_QUERY,
            variables: {
              orderBy:
                Object.keys(orderByOptions).length === 0
                  ? defaultOrderBy
                  : orderByOptions,
              filters: filterOptions,
              countFilters: filterOptions,
              limit: itemsPerPage,
              offset: (currentPage - 1) * itemsPerPage,
            },
            notifyOnNetworkStatusChange: true,
          },
        ],
        onError: async (error) => {
          await messageApi.error(t('create_error'));
          Sentry.captureMessage(error.message);
        },
      });
    }
  };

  return (
    <Drawer
      title={t('title')}
      onClose={() => handleCloseDrawer()}
      open={showNewDeliveryDrawer}
      width={520}
    >
      {contextHolder}

      <Modal
        title={t('cancel_modal.title')}
        open={showCancelDialog}
        onOk={() => closeDrawer()}
        onCancel={() => setShowCancelDialog(false)}
        okText={t('cancel_modal.confirm')}
      >
        <Typography.Text>{t('cancel_modal.message')}</Typography.Text>
      </Modal>

      <FieldWrapper>
        <FieldLabel>{t('name_label')}</FieldLabel>
        <InputWrapper>
          {recipient ? (
            <SelectedUser
              user={recipient}
              onClear={() => {
                setRecipient(null);
              }}
              canClear={true}
            />
          ) : (
            <UserSelect
              placeholder={t('name_placeholder')}
              onSelectUser={(user) => setRecipient(user)}
              errorText="add a user"
              formError={false}
              onBlur={() => {}}
              onChange={setRecipientName}
              showErrors={recipientNameError}
              showParent={showNewDeliveryDrawer}
            />
          )}
          {showErrors && recipientNameError && (
            <InputError type="danger">{t('name_error')}</InputError>
          )}
        </InputWrapper>
      </FieldWrapper>

      <FieldWrapper>
        <FieldLabel>{t('email_label')}</FieldLabel>
        <InputWrapper>
          <Input
            placeholder={t('email_placeholder')}
            value={recipientEmail}
            onChange={(event) => setRecipientEmail(event.target.value)}
            status={showErrors && recipientEmailError ? 'error' : undefined}
          />
          {showErrors && recipientEmailError && (
            <InputError type="danger">{t('email_error')}</InputError>
          )}
        </InputWrapper>
      </FieldWrapper>

      <FieldWrapper>
        <FieldLabel>{t('carrier_label')}</FieldLabel>
        <Input
          placeholder={t('carrier_placeholder')}
          value={carrier}
          onChange={(event) => setCarrier(event.target.value)}
        />
      </FieldWrapper>

      <FieldWrapper>
        <FieldLabel>{t('zone_label')}</FieldLabel>
        <ConfigProvider renderEmpty={DeliveryZoneDropdownEmptyState}>
          <InputWrapper>
            <Select
              onChange={(event) => {
                setDeliveryZoneId(event);
              }}
              options={deliveryZones?.map((zone) => ({
                value: zone.id,
                label: zone.name,
              }))}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              placeholder={t('zone_placeholder')}
              showSearch
              status={showErrors && deliveryZoneIdError ? 'error' : undefined}
              style={{ width: '100%' }}
              value={deliveryZoneId}
            />
            {showErrors && deliveryZoneIdError && (
              <InputError type="danger">{t('zone_error')}</InputError>
            )}
          </InputWrapper>
        </ConfigProvider>
      </FieldWrapper>

      <FieldWrapper>
        <FieldLabel>{t('notes_label')}</FieldLabel>
        <Input.TextArea
          placeholder={t('notes_placeholder')}
          rows={4}
          onChange={(event) => setDeliveryNotes(event.target.value)}
          value={deliveryNotes}
        />
      </FieldWrapper>

      <StyledFooter>
        <TooltipWrapper>
          <InfoSolid size={16} color={Colors.Gray60} />
          <StyledTooltipText>{t('notifications_tooltip')}</StyledTooltipText>
        </TooltipWrapper>
        <ButtonWrapper>
          <StyledCreateButton
            loading={deliveryCreationLoading}
            type="primary"
            size="large"
            onClick={() => handleSubmitForm()}
          >
            {t('create')}
          </StyledCreateButton>
          <Button
            disabled={deliveryCreationLoading}
            type="default"
            size="large"
            onClick={() => handleCloseDrawer()}
          >
            {t('cancel')}
          </Button>
        </ButtonWrapper>
      </StyledFooter>
    </Drawer>
  );
};

const FieldWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
`;

const FieldLabel = styled(Typography.Text)`
  font-size: 14px;
  line-height: 22px;
  min-width: 150px;
  padding: 4px 0;
`;

const StyledFooter = styled.footer`
  border-top: var(--Components-Drawer-Global-lineWidth, 1px) solid
    var(--Components-Drawer-Global-colorSplit, rgba(0, 0, 0, 0.06));
  position: absolute;
  bottom: 0;
  left: 0;
  padding: var(--Space-Padding-padding, 16px);
  width: 100%;
`;

const TooltipWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: var(--Space-Margin-marginSM, 12px);
`;

const StyledTooltipText = styled(Typography.Text)`
  color: var(--Components-Drawer-Global-colorText, rgba(0, 0, 0, 0.9));
  font-size: var(--Typography-Font-Size-fontSizeSM, 12px);
  font-weight: var(--Typography-Font-Weight-fontWeightNormal, 400);
  line-height: var(--Components-Drawer-Global-lineHeightLG, 24px);
  margin-left: var(--Space-Margin-marginXS, 8px);
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const StyledCreateButton = styled(Button)`
  margin-right: 8px;
`;

const InputWrapper = styled.div`
  width: 100%;
`;

const InputError = styled(Typography.Text)`
  display: block;
  margin-top: 2px;
`;
