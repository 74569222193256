import { getUsersByKeyword_getUsersByKeyword } from 'components';
import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import { emailRegex } from '../constants';
import { useCreateDelivery } from '../hooks';
import { MutationFunctionOptions } from '@apollo/client';
import {
  CreateDeliveryInput,
  DeliveryDrawerCreateDeliveryMutation,
  Exact,
} from 'generated/graphql';

type DeliveryFormContextValue = {
  carrier: string;
  recipient: getUsersByKeyword_getUsersByKeyword | null;
  recipientName: string;
  recipientEmail: string;
  recipientEmailError: boolean;
  recipientNameError: boolean;
  deliveryZoneId: string | null;
  deliveryZoneIdError: boolean;
  deliveryNotes: string;
  isFormValid: boolean;
  showErrors: boolean;
  hasFormChanged: boolean;
  deliveryCreationLoading: boolean;
  createDelivery: (
    options?: MutationFunctionOptions<
      DeliveryDrawerCreateDeliveryMutation,
      Exact<{ input: CreateDeliveryInput }>
    >
  ) => void;
  setRecipient: (recipient: getUsersByKeyword_getUsersByKeyword | null) => void;
  setRecipientName: (name: string) => void;
  setRecipientEmail: (email: string) => void;
  setCarrier: (carrier: string) => void;
  setDeliveryZoneId: (zone: string | null) => void;
  setDeliveryNotes: (notes: string) => void;
  setShowErrors: (show: boolean) => void;
  clearValues: () => void;
};

const DeliveryFormContext = createContext<DeliveryFormContextValue>({
  carrier: '',
  recipient: null,
  recipientName: '',
  recipientEmail: '',
  deliveryNotes: '',
  recipientEmailError: false,
  recipientNameError: false,
  deliveryZoneId: null,
  deliveryZoneIdError: false,
  isFormValid: false,
  showErrors: false,
  hasFormChanged: false,
  deliveryCreationLoading: false,
  createDelivery: () => null,
  setRecipient: () => null,
  setRecipientName: () => null,
  setRecipientEmail: () => null,
  setCarrier: () => null,
  setDeliveryZoneId: () => null,
  setDeliveryNotes: () => null,
  setShowErrors: () => null,
  clearValues: () => null,
});

export const DeliveryFormContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [recipient, setRecipient] =
    useState<getUsersByKeyword_getUsersByKeyword | null>(null);
  const [recipientName, setRecipientName] = useState('');
  const [recipientEmail, setRecipientEmail] = useState('');
  const [carrier, setCarrier] = useState('');
  const [deliveryZoneId, setDeliveryZoneId] = useState<string | null>(null);
  const [deliveryNotes, setDeliveryNotes] = useState('');
  const [showErrors, setShowErrors] = useState(false);
  const [createDelivery, { loading: deliveryCreationLoading }] =
    useCreateDelivery();
  const recipientNameError = recipientEmail.length > 0 && !recipientName.length;
  const recipientEmailError =
    (recipientName.length > 0 && !recipientEmail.length) ||
    (recipientEmail.length > 0 && !emailRegex.test(recipientEmail));
  const deliveryZoneIdError = deliveryZoneId === null;
  const isFormValid =
    !recipientNameError && !recipientEmailError && !deliveryZoneIdError;
  const hasFormChanged =
    recipient !== null ||
    recipientName.length > 0 ||
    recipientEmail.length > 0 ||
    carrier.length > 0 ||
    deliveryZoneId !== null ||
    deliveryNotes.length > 0;

  const clearValues = () => {
    setRecipient(null);
    setRecipientName('');
    setRecipientEmail('');
    setCarrier('');
    setDeliveryZoneId(null);
    setDeliveryNotes('');
    setShowErrors(false);
  };

  useEffect(() => {
    setRecipientName(recipient?.name || '');
    setRecipientEmail(recipient?.primaryEmail?.email || '');
  }, [recipient]);

  return (
    <DeliveryFormContext.Provider
      value={{
        carrier,
        recipient,
        recipientName,
        recipientEmail,
        deliveryZoneId,
        deliveryNotes,
        recipientEmailError,
        recipientNameError,
        deliveryZoneIdError,
        isFormValid,
        showErrors,
        hasFormChanged,
        deliveryCreationLoading,
        createDelivery,
        setRecipient,
        setRecipientName,
        setRecipientEmail,
        setCarrier,
        setDeliveryZoneId,
        setDeliveryNotes,
        setShowErrors,
        clearValues,
      }}
    >
      {children}
    </DeliveryFormContext.Provider>
  );
};

export const useDeliveryFormContext = (): DeliveryFormContextValue => {
  return useContext(DeliveryFormContext);
};
