import { Drawer, Modal, Typography, message } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import {
  useDeliveriesTableContext,
  useDeliveryDetailsContext,
  useDeliveryEditContext,
} from '../contexts';
import { DeliveryDetailsDrawer } from './DeliveryDetailsDrawer';
import { DeliveryEditDrawer } from './DeliveryEditDrawer';
import { Sentry } from 'lib/sentry';
import { DELIVERIES_QUERY, defaultOrderBy } from '../hooks';

export const DeliveryDrawer = () => {
  const { t } = useTranslation(['deliveryDetails', 'deliveriesForm']);
  const [messageApi, contextHolder] = message.useMessage();
  const { selectedDelivery, setSelectedDelivery } = useDeliveryDetailsContext();
  const {
    deliveryToEdit,
    additionalNotes,
    deliveryZoneId,
    deliveryStatus,
    recipient,
    recipientEmail,
    recipientName,
    isFormValid,
    showCancelDialog,
    clearValues,
    handleCloseEditDrawer,
    setShowCancelDialog,
    setShowErrors,
  } = useDeliveryEditContext();
  const {
    filterOptions,
    orderByOptions = {},
    currentPage = 1,
    itemsPerPage = 100,
    updateDelivery,
  } = useDeliveriesTableContext();

  const handleSubmitForm = () => {
    setShowErrors(true);

    if (isFormValid && deliveryToEdit) {
      const variables = {
        id: deliveryToEdit.id,
        input: {
          deliveryZoneId: deliveryZoneId,
          status: deliveryStatus,
          recipient:
            recipientEmail && recipientName
              ? {
                  name: recipientName,
                  email: recipientEmail,
                  userId: recipient?.id,
                }
              : null,
          additionalNotes: additionalNotes,
        },
      };

      updateDelivery({
        variables,
        onCompleted: async () => {
          clearValues();
          await messageApi.success(t('edit_success'));
        },
        // Note: these variables must match the deliveries query variables exactly in
        // order for the apollo cache to be updated correctly and replace the stale data
        refetchQueries: [
          {
            query: DELIVERIES_QUERY,
            variables: {
              orderBy:
                Object.keys(orderByOptions).length === 0
                  ? defaultOrderBy
                  : orderByOptions,
              filters: filterOptions,
              countFilters: filterOptions,
              limit: itemsPerPage,
              offset: (currentPage - 1) * itemsPerPage,
            },
            notifyOnNetworkStatusChange: true,
          },
        ],
        onError: async (error) => {
          await messageApi.error(t('edit_error'));
          Sentry.captureMessage(error.message);
        },
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Drawer
        title={deliveryToEdit ? t('edit_title') : t('title')}
        onClose={() =>
          deliveryToEdit ? handleCloseEditDrawer() : setSelectedDelivery(null)
        }
        open={!!selectedDelivery || !!deliveryToEdit}
        width={520}
      >
        <Modal
          title={t('cancel_modal.title', {
            ns: 'deliveriesForm',
          })}
          open={showCancelDialog}
          onOk={clearValues}
          onCancel={() => setShowCancelDialog(false)}
          okText={t('cancel_modal.confirm', {
            ns: 'deliveriesForm',
          })}
        >
          <Typography.Text>
            {t('cancel_modal.message', { ns: 'deliveriesForm' })}
          </Typography.Text>
        </Modal>
        {selectedDelivery && !deliveryToEdit && <DeliveryDetailsDrawer />}
        {deliveryToEdit && (
          <DeliveryEditDrawer handleSubmitForm={handleSubmitForm} />
        )}
      </Drawer>
    </>
  );
};
