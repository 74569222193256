import { Table } from '@robinpowered/ui-kit';
import styled from '@emotion/styled';
import { useDeliveryZonesContext } from 'pages/DeliveriesPage/contexts';
import { useDeliveryZonesTableData } from '../hooks';
import { EditableCell } from '.';

export const DeliveryZonesTable = () => {
  const { columns } = useDeliveryZonesTableData();
  const { tableData, isLoading } = useDeliveryZonesContext();

  const components = {
    body: {
      cell: EditableCell,
    },
  };

  return (
    <StyledWrapper>
      <Table
        dataSource={tableData}
        components={components}
        columns={columns}
        onChange={(pagination, filters, sorter) => {}}
        pagination={false}
        loading={isLoading}
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  td {
    vertical-align: middle;
    max-width: 125px;
    min-width: 125px;
  }
`;
