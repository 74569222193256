import moment from 'moment';
import { Button, Typography, Input, Select } from '@robinpowered/ui-kit';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import {
  useDeliveriesTableContext,
  useDeliveryEditContext,
  useDeliveryZonesContext,
} from '../contexts';
import { SelectedUser } from 'components/SelectedUser';
import { UserSelect } from 'components';
import { ChangeEvent } from 'react';
import { DeliveryStatusSelect } from './DeliveryStatusSelect';
import { DeliveryStatus } from 'generated/graphql';

type DeliveryEditDrawerProps = {
  handleSubmitForm: () => void;
};

export const DeliveryEditDrawer = ({
  handleSubmitForm,
}: DeliveryEditDrawerProps) => {
  const { t } = useTranslation(['deliveryDetails', 'deliveriesForm']);
  const {
    additionalNotes,
    deliveryToEdit,
    deliveryStatus,
    deliveryZoneId,
    recipient,
    recipientEmail,
    recipientNameError,
    recipientEmailError,
    showErrors,
    handleCloseEditDrawer,
    updateAdditionalNotes,
    updateDeliveryZoneId,
    updateRecipient,
    updateDeliveryStatus,
    updateRecipientName,
    updateRecipientEmail,
  } = useDeliveryEditContext();
  const { createDeleteModalConfig, modal } = useDeliveriesTableContext();
  const { deliveryZones } = useDeliveryZonesContext();
  const { loading: deliveryUpdating } = useDeliveriesTableContext();

  return (
    <>
      <Wrapper>
        <FieldWrapper>
          <FieldName>
            <Typography.Text size="base">{t('delivery_id')}</Typography.Text>
          </FieldName>
          <FieldValue>
            <Input value={deliveryToEdit?.id} disabled />
          </FieldValue>
        </FieldWrapper>

        <FieldWrapper>
          <FieldName>
            <Typography.Text size="base">{t('recipient_name')}</Typography.Text>
          </FieldName>
          <FieldValue>
            {recipient ? (
              <SelectedUser
                user={recipient}
                onClear={() => {
                  updateRecipient(null);
                }}
              />
            ) : (
              <UserSelect
                placeholder={t('name_placeholder', {
                  ns: 'deliveriesForm',
                })}
                onSelectUser={(user) => updateRecipient(user)}
                errorText={t('edit_add_user_error')}
                formError={false}
                onBlur={() => {}}
                onChange={updateRecipientName}
                showErrors={recipientNameError}
                showParent={false}
              />
            )}
            {showErrors && recipientNameError && (
              <InputError type="danger">
                {t('name_error', {
                  ns: 'deliveriesForm',
                })}
              </InputError>
            )}
          </FieldValue>
        </FieldWrapper>

        <FieldWrapper>
          <FieldName>
            <Typography.Text size="base">
              {t('recipient_email')}
            </Typography.Text>
          </FieldName>
          <FieldValue>
            <Input
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                updateRecipientEmail(e.target.value)
              }
              placeholder={t('email_placeholder', {
                ns: 'deliveriesForm',
              })}
              status={showErrors && recipientEmailError ? 'error' : undefined}
              value={recipientEmail}
            />
            {showErrors && recipientEmailError && (
              <InputError type="danger">
                {t('email_error', {
                  ns: 'deliveriesForm',
                })}
              </InputError>
            )}
          </FieldValue>
        </FieldWrapper>

        {deliveryStatus && (
          <FieldWrapper>
            <FieldName>
              <Typography.Text size="base">{t('status')}</Typography.Text>
            </FieldName>
            <FieldValue>
              <DeliveryStatusSelect
                status={deliveryStatus}
                onChange={(status) =>
                  updateDeliveryStatus(status as DeliveryStatus)
                }
              />
            </FieldValue>
          </FieldWrapper>
        )}

        <FieldWrapper>
          <FieldName>
            <Typography.Text size="base">{t('delivery_zone')}</Typography.Text>
          </FieldName>
          <FieldValue>
            <Select
              onChange={(event) => {
                updateDeliveryZoneId(event);
              }}
              options={deliveryZones?.map((zone) => ({
                value: zone.id,
                label: zone.name,
              }))}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              placeholder={t('zone_placeholder')}
              showSearch
              // status={showErrors && deliveryZoneIdError ? 'error' : undefined}
              style={{ width: '100%' }}
              value={deliveryZoneId}
            />
          </FieldValue>
        </FieldWrapper>

        {deliveryToEdit?.carrier && (
          <FieldWrapper>
            <FieldName>
              <Typography.Text size="base">{t('carrier')}</Typography.Text>
            </FieldName>
            <FieldValue>
              <Input value={deliveryToEdit.carrier} disabled />
            </FieldValue>
          </FieldWrapper>
        )}

        <FieldWrapper>
          <FieldName>
            <Typography.Text size="base">{t('pickup_date')}</Typography.Text>
          </FieldName>
          <FieldValue>
            <Input
              value={
                deliveryToEdit?.pickedUpAt
                  ? moment(deliveryToEdit.pickedUpAt).format('ll')
                  : '-'
              }
              disabled
            />
          </FieldValue>
        </FieldWrapper>

        <FieldWrapper>
          <FieldName>
            <Typography.Text size="base">{t('notified_on')}</Typography.Text>
          </FieldName>
          <FieldValue>
            <Input
              value={
                deliveryToEdit?.lastNotifiedAt
                  ? moment(deliveryToEdit.lastNotifiedAt).format('ll')
                  : '-'
              }
              disabled
            />
          </FieldValue>
        </FieldWrapper>

        <FieldWrapper>
          <FieldName>
            <Typography.Text size="base">{t('created_on')}</Typography.Text>
          </FieldName>
          <FieldValue>
            <Input
              value={
                deliveryToEdit?.createdAt
                  ? moment(deliveryToEdit?.createdAt).format('ll')
                  : '-'
              }
              disabled
            />
          </FieldValue>
        </FieldWrapper>

        {deliveryToEdit?.createdBy && (
          <FieldWrapper>
            <FieldName>
              <Typography.Text size="base">{t('created_by')}</Typography.Text>
            </FieldName>
            <FieldValue>
              <SelectedUser
                user={deliveryToEdit.createdBy}
                onClear={() => updateRecipient(null)}
                canClear={false}
                isDisabled
              />
            </FieldValue>
          </FieldWrapper>
        )}

        {deliveryToEdit?.additionalNotes && (
          <FieldWrapperTopAligned>
            <FieldName>
              <Typography.Text size="base">
                {t('additional_notes')}
              </Typography.Text>
            </FieldName>
            <FieldValue>
              <Input.TextArea
                value={additionalNotes}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                  updateAdditionalNotes(e.target.value)
                }
                rows={4}
              />
            </FieldValue>
          </FieldWrapperTopAligned>
        )}
      </Wrapper>
      <StyledFooter>
        <StyledCreateButton
          type="primary"
          size="large"
          onClick={handleSubmitForm}
          loading={deliveryUpdating}
        >
          {t('footer.save')}
        </StyledCreateButton>
        <Button
          type="default"
          size="large"
          onClick={handleCloseEditDrawer}
          disabled={deliveryUpdating}
        >
          {t('footer.cancel')}
        </Button>
        <Button
          danger
          type="link"
          size="large"
          disabled={deliveryUpdating}
          onClick={async () => {
            if (deliveryToEdit) {
              await modal.warning(createDeleteModalConfig([deliveryToEdit.id]));
            }
          }}
        >
          {t('footer.delete')}
        </Button>
      </StyledFooter>
    </>
  );
};

const Wrapper = styled.div`
  padding: var(--Space-Margin-marginXS, 8px);
  padding-bottom: 73px; // 73px is the height of the footer
`;

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const FieldWrapperTopAligned = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
`;

const FieldName = styled.div`
  padding: 4px 0;
  width: 35%;
`;

const FieldValue = styled.div`
  padding: 4px 0;
  width: 65%;
`;

const StyledFooter = styled.footer`
  background: var(--Colors-Neutral-colorWhite, #fff);
  border-top: var(--Components-Drawer-Global-lineWidth, 1px) solid
    var(--Components-Drawer-Global-colorSplit, rgba(0, 0, 0, 0.06));
  position: absolute;
  bottom: 0;
  left: 0;
  padding: var(--Space-Padding-padding, 16px);
  width: 100%;
`;

const StyledCreateButton = styled(Button)`
  margin-right: 8px;
`;

const InputError = styled(Typography.Text)`
  display: block;
  margin-top: 2px;
`;
