import { useMutation, gql, MutationTuple } from '@apollo/client';
import {
  DeliveryTableNotifyRecipientsMutation,
  DeliveryTableNotifyRecipientsMutationVariables,
} from 'generated/graphql';

export const NOTIFY_RECIPIENT = gql`
  mutation DeliveryTableNotifyRecipients($deliveryIds: [ID!]!) {
    sendDeliveryNotificationsToRecipients(deliveryIds: $deliveryIds)
  }
`;

export const useNotifyDeliveryRecipients = (): MutationTuple<
  DeliveryTableNotifyRecipientsMutation,
  DeliveryTableNotifyRecipientsMutationVariables
> => {
  return useMutation<
    DeliveryTableNotifyRecipientsMutation,
    DeliveryTableNotifyRecipientsMutationVariables
  >(NOTIFY_RECIPIENT);
};
