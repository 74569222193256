import { gql, MutationTuple, useMutation } from '@apollo/client';
import {
  CreateDeliveryZoneMutation,
  CreateDeliveryZoneMutationVariables,
} from 'generated/graphql';

export const CREATE_DELIVERY_ZONE = gql`
  mutation CreateDeliveryZone($input: CreateDeliveryZoneInput!) {
    createDeliveryZone(input: $input) {
      id
    }
  }
`;

export const useCreateDeliveryZone = (): MutationTuple<
  CreateDeliveryZoneMutation,
  CreateDeliveryZoneMutationVariables
> => {
  return useMutation<
    CreateDeliveryZoneMutation,
    CreateDeliveryZoneMutationVariables
  >(CREATE_DELIVERY_ZONE);
};
