import styled from '@emotion/styled/macro';
import { Colors, Row, Box } from '@robinpowered/design-system';
import { Avatar, Typography } from '@robinpowered/ui-kit';
import Close from '@robinpowered/icons/Close';
import { getUsersByKeyword_getUsersByKeyword } from 'components/UserSelect';
import { User } from 'generated/graphql';

type PropTypes = {
  isDisabled?: boolean;
  user:
    | getUsersByKeyword_getUsersByKeyword
    | User
    | { name: string; avatar?: string };
  onClear: () => void;
  canClear?: boolean;
};

export const SelectedUser = ({
  isDisabled,
  user,
  onClear,
  canClear = true,
}: PropTypes): JSX.Element => {
  return (
    <Card isDisabled={isDisabled}>
      <Row alignItems="center">
        <Avatar alt={user.name ?? ''} src={user.avatar ?? ''} size={20} />
        <Username>{user.name}</Username>
      </Row>
      {canClear && (
        <Row>
          <Close size={16} color={Colors.Gray60} onClick={onClear} />
        </Row>
      )}
    </Card>
  );
};

const Card = styled(Box)<{ isDisabled: boolean }>`
  border-radius: 4px;
  border: 1px solid ${Colors.Gray40};
  width: 100%;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ isDisabled }) =>
    isDisabled &&
    `
    background-color: ${Colors.Gray5};
    color: ${Colors.Gray40};
  `}
`;

const Username = styled(Typography.Text)`
  margin-left: 8px;
  font-size: 14px;
`;
