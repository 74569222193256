import moment from 'moment';
import { Button, Typography } from '@robinpowered/ui-kit';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import {
  useDeliveriesTableContext,
  useDeliveryDetailsContext,
  useDeliveryEditContext,
} from '../contexts';
import { DeliveryStatusTag } from './DeliveryStatusTag';
import { Recipient } from './Recipient';

export const DeliveryDetailsDrawer = () => {
  const { t } = useTranslation(['deliveryDetails', 'deliveriesForm']);
  const { createDeleteModalConfig, createNotificationConfig, modal } =
    useDeliveriesTableContext();
  const { selectedDelivery, setSelectedDelivery } = useDeliveryDetailsContext();
  const { setDeliveryToEdit } = useDeliveryEditContext();

  return (
    <>
      <Wrapper>
        <FieldWrapper>
          <FieldName>
            <Typography.Text size="base">{t('delivery_id')}</Typography.Text>
          </FieldName>
          <FieldValue>
            <Typography.Text size="base">
              {selectedDelivery?.id.toUpperCase()}
            </Typography.Text>
          </FieldValue>
        </FieldWrapper>

        {selectedDelivery?.recipient?.name && (
          <FieldWrapper>
            <FieldName>
              <Typography.Text size="base">
                {t('recipient_name')}
              </Typography.Text>
            </FieldName>
            <FieldValue>
              <Typography.Text size="base">
                {selectedDelivery.recipient.name}
              </Typography.Text>
            </FieldValue>
          </FieldWrapper>
        )}

        {selectedDelivery?.recipient?.email && (
          <FieldWrapper>
            <FieldName>
              <Typography.Text size="base">
                {t('recipient_email')}
              </Typography.Text>
            </FieldName>
            <FieldValue>
              <Typography.Text size="base">
                {selectedDelivery.recipient.email}
              </Typography.Text>
            </FieldValue>
          </FieldWrapper>
        )}

        {selectedDelivery?.status && (
          <FieldWrapper>
            <FieldName>
              <Typography.Text size="base">{t('status')}</Typography.Text>
            </FieldName>
            <FieldValue>
              <Typography.Text size="base">
                <DeliveryStatusTag status={selectedDelivery.status} />
              </Typography.Text>
            </FieldValue>
          </FieldWrapper>
        )}

        {selectedDelivery?.deliveryZone.name && (
          <FieldWrapper>
            <FieldName>
              <Typography.Text size="base">
                {t('delivery_zone')}
              </Typography.Text>
            </FieldName>
            <FieldValue>
              <Typography.Text size="base">
                {selectedDelivery.deliveryZone.name}
              </Typography.Text>
            </FieldValue>
          </FieldWrapper>
        )}

        {selectedDelivery?.carrier && (
          <FieldWrapper>
            <FieldName>
              <Typography.Text size="base">{t('carrier')}</Typography.Text>
            </FieldName>
            <FieldValue>
              <Typography.Text size="base">
                {selectedDelivery.carrier}
              </Typography.Text>
            </FieldValue>
          </FieldWrapper>
        )}

        {selectedDelivery && (
          <FieldWrapper>
            <FieldName>
              <Typography.Text size="base">{t('pickup_date')}</Typography.Text>
            </FieldName>
            <FieldValue>
              <Typography.Text size="base">
                {selectedDelivery.pickedUpAt
                  ? moment(selectedDelivery.pickedUpAt).format('ll')
                  : '-'}
              </Typography.Text>
            </FieldValue>
          </FieldWrapper>
        )}

        {selectedDelivery?.lastNotifiedAt && (
          <FieldWrapper>
            <FieldName>
              <Typography.Text size="base">{t('notified_on')}</Typography.Text>
            </FieldName>
            <FieldValue>
              <Typography.Text size="base">
                {moment(selectedDelivery.lastNotifiedAt).format('ll')}
              </Typography.Text>
            </FieldValue>
          </FieldWrapper>
        )}

        {selectedDelivery?.createdAt && (
          <FieldWrapper>
            <FieldName>
              <Typography.Text size="base">{t('created_on')}</Typography.Text>
            </FieldName>
            <FieldValue>
              <Typography.Text size="base">
                {moment(selectedDelivery.createdAt).format('ll')}
              </Typography.Text>
            </FieldValue>
          </FieldWrapper>
        )}

        {selectedDelivery?.createdBy && (
          <FieldWrapper>
            <FieldName>
              <Typography.Text size="base">{t('created_by')}</Typography.Text>
            </FieldName>
            <FieldValue>
              <Recipient
                name={selectedDelivery.createdBy.name}
                avatar={selectedDelivery.createdBy.avatar}
              />
            </FieldValue>
          </FieldWrapper>
        )}

        {selectedDelivery?.additionalNotes && (
          <FieldWrapperTopAligned>
            <FieldName>
              <Typography.Text size="base">
                {t('additional_notes')}
              </Typography.Text>
            </FieldName>
            <FieldValue>
              <Typography.Text size="base">
                {selectedDelivery.additionalNotes}
              </Typography.Text>
            </FieldValue>
          </FieldWrapperTopAligned>
        )}
      </Wrapper>
      <StyledFooter>
        <StyledCreateButton
          type="primary"
          size="large"
          onClick={async () => {
            if (selectedDelivery) {
              await modal.warning(
                createNotificationConfig([selectedDelivery.id])
              );
            }
          }}
        >
          {t('footer.remind')}
        </StyledCreateButton>
        <Button
          type="default"
          size="large"
          onClick={() => {
            setDeliveryToEdit(selectedDelivery);
            setSelectedDelivery(null);
          }}
        >
          {t('footer.edit')}
        </Button>
        <Button
          danger
          type="link"
          size="large"
          onClick={async () => {
            if (selectedDelivery) {
              await modal.warning(
                createDeleteModalConfig([selectedDelivery.id])
              );
            }
          }}
        >
          {t('footer.delete')}
        </Button>
      </StyledFooter>
    </>
  );
};

const Wrapper = styled.div`
  padding: var(--Space-Margin-marginXS, 8px);
  padding-bottom: 73px; // 73px is the height of the footer
`;

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const FieldWrapperTopAligned = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
`;

const FieldName = styled.div`
  width: 35%;
  padding: 4px 0;
`;

const FieldValue = styled.div`
  width: 65%;
  padding: 4px 0;
`;

const StyledFooter = styled.footer`
  background: var(--Colors-Neutral-colorWhite, #fff);
  border-top: var(--Components-Drawer-Global-lineWidth, 1px) solid
    var(--Components-Drawer-Global-colorSplit, rgba(0, 0, 0, 0.06));
  position: absolute;
  bottom: 0;
  left: 0;
  padding: var(--Space-Padding-padding, 16px);
  width: 100%;
`;

const StyledCreateButton = styled(Button)`
  margin-right: 8px;
`;
