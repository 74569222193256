import styled from '@emotion/styled';
import { Avatar, Typography } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons';

type RecipientProps = {
  name?: string | null;
  avatar?: string | null;
};

export const Recipient = ({ name, avatar }: RecipientProps): JSX.Element => {
  const { t } = useTranslation('deliveriesTable');
  const isPlaceholder = !name;
  return (
    <AvatarWrapper>
      <StyledAvatar
        size={'small'}
        src={avatar}
        icon={!avatar ? <UserOutlined /> : undefined}
      />
      <AvatarText isPlaceholder={isPlaceholder}>
        {name || t('table.noRecipient')}
      </AvatarText>
    </AvatarWrapper>
  );
};

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledAvatar = styled(Avatar)`
  min-width: 28px;
`;

const AvatarText = styled(Typography.Text)<{ isPlaceholder: boolean }>`
  margin-left: 16px;
  color: ${({ isPlaceholder }) =>
    isPlaceholder ? '#8c8c8c' : 'inherit'}; // Gray color if placeholder
`;
