import { gql } from '@apollo/client';
import { useAuthContext } from 'contexts';
import { useGetDeliveryRecipientsQuery } from 'generated/graphql';

export const DELIVERY_RECIPIENTS_QUERY = gql`
  query GetDeliveryRecipients($queryString: String) {
    getRecipients(queryString: $queryString) {
      id
      name
      email
      user {
        avatar
      }
    }
  }
`;

export const useGetDeliveryRecipients = (queryString: string) => {
  const { currentOrg } = useAuthContext();
  const { data, loading, error, refetch } = useGetDeliveryRecipientsQuery({
    skip: !currentOrg || queryString.length === 0,
    variables: {
      queryString,
    },
  });

  return {
    recipients: data?.getRecipients || [],
    recipientsLoading: loading,
    recipientsError: error,
    refetchRecipients: refetch,
  };
};
