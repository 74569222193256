import {
  useState,
  useEffect,
  useCallback,
  KeyboardEvent,
  FocusEventHandler,
} from 'react';
import { useUserSearch, MIN_KEYWORD_LENGTH } from './useUserSearch';
import { getUsersByKeyword_getUsersByKeyword } from './__generated__/getUsersByKeyword';
import { UserSelect } from './UserSelect';

type PropTypes = {
  placeholder?: string;
  errorText?: string;
  formError?: boolean;
  onSelectUser: (user: getUsersByKeyword_getUsersByKeyword) => void;
  onChange: (value: string) => void;
  onBlur: FocusEventHandler<HTMLSpanElement>;
  showErrors?: boolean;
  showParent?: boolean;
};

export const UserSelectConnector = ({
  placeholder,
  errorText,
  formError,
  onChange,
  showErrors,
  showParent,
  onSelectUser,
  onBlur,
}: PropTypes): JSX.Element => {
  const { keyword, setKeyword, users, loading, error } = useUserSearch();
  const [inputLabel, setInputLabel] = useState('');
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    setInputLabel(keyword);
  }, [keyword]);

  useEffect(() => {
    if (!showParent) {
      setInputLabel('');
      setKeyword('');
    }
  }, [showParent, setKeyword]);

  const shouldShowResultsMenu = useCallback(() => {
    return (
      (!!users || loading || !!error) && keyword.length >= MIN_KEYWORD_LENGTH
    );
  }, [users, loading, error, keyword]);

  useEffect(() => {
    setShowResults(shouldShowResultsMenu());
  }, [loading, keyword, shouldShowResultsMenu]);

  const onFocus = () => {
    if (shouldShowResultsMenu()) {
      setShowResults(true);
    }
  };

  const onTextChange = (value: string) => {
    setKeyword(value);
    onChange(value);
  };

  const onClickAway = () => {
    setShowResults(false);
  };

  const onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Tab') {
      // Close menu when tabbing away
      setShowResults(false);
    } else if (
      event.key === 'ArrowDown' &&
      !showResults &&
      shouldShowResultsMenu()
    ) {
      // Show menu on down arrow if it's hidden and is eligible to be shown
      setShowResults(true);
    }
  };

  const handleSelectUser = (user: getUsersByKeyword_getUsersByKeyword) => {
    onSelectUser(user);
    setShowResults(false);
    setInputLabel(user.name ?? user.primaryEmail?.email ?? '');
  };

  return (
    <UserSelect
      loading={loading}
      value={inputLabel}
      error={error}
      formError={formError}
      errorText={errorText}
      users={users}
      showErrors={showErrors}
      placeholder={placeholder}
      showResults={showResults}
      onFocus={onFocus}
      onChange={onTextChange}
      onBlur={onBlur}
      onSelectUser={handleSelectUser}
      onKeyDown={onKeyDown}
      onClickAway={onClickAway}
    />
  );
};
