import { Button } from '@robinpowered/ui-kit';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useDeliveryZonesContext } from 'pages/DeliveriesPage/contexts';

type TableActionButtonsProps = {
  createOrEditMode: boolean;
  setRecordKey: () => void;
  onCancelEdit: () => void;
};

export const TableActionButtons = ({
  createOrEditMode,
  setRecordKey,
  onCancelEdit,
}: TableActionButtonsProps) => {
  const { t } = useTranslation('deliveryZonesTable');
  const {
    handleCreateDeliveryZone,
    handleUpdateDeliveryZone,
    handleCancelCreate,
    setIsDeleting,
    setIsDeleteModalOpen,
    zoneName,
    zoneLocation,
    recordKey,
    isAddingDeliveryZone,
  } = useDeliveryZonesContext();

  return (
    <Container>
      {createOrEditMode ? (
        <>
          <span title={t(`buttons.save`)}>
            <Button
              type="link"
              size="small"
              onClick={
                recordKey ? handleUpdateDeliveryZone : handleCreateDeliveryZone
              }
              disabled={!zoneName?.trim() || !zoneLocation}
            >
              {t('buttons.save')}
            </Button>
          </span>
          <span title={t(`buttons.cancel`)}>
            <Button
              type="link"
              size="small"
              onClick={recordKey ? onCancelEdit : handleCancelCreate}
            >
              {t('buttons.cancel')}
            </Button>
          </span>
        </>
      ) : (
        <>
          <span title={t(`buttons.edit`)}>
            <Button
              type="link"
              size="small"
              onClick={setRecordKey}
              disabled={isAddingDeliveryZone || !!recordKey}
            >
              {t('buttons.edit')}
            </Button>
          </span>
          <span title={t(`buttons.delete`)}>
            <Button
              type="link"
              size="small"
              onClick={() => {
                setIsDeleting(true);
                setRecordKey();
                setIsDeleteModalOpen(true);
              }}
              disabled={isAddingDeliveryZone || !!recordKey}
            >
              {t('buttons.delete')}
            </Button>
          </span>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;
