import { gql, useQuery } from '@apollo/client';
import { useAuthContext } from 'contexts';
import { GetDeliveryLocationsQuery } from 'generated/graphql';

export const DELIVERY_LOCATIONS_QUERY = gql`
  query GetDeliveryLocations($orgId: ID!) {
    getOrganizationById(id: $orgId) {
      id
      locations {
        id
        name
        timezone
        levels {
          name
          id
        }
        spaces {
          name
          id
        }
      }
    }
  }
`;

export const useGetDeliveryLocations = () => {
  const { currentOrg } = useAuthContext();

  const { data, loading, error, refetch } = useQuery<GetDeliveryLocationsQuery>(
    DELIVERY_LOCATIONS_QUERY,
    {
      skip: !currentOrg,
      variables: {
        orgId: currentOrg?.id,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-and-network',
    }
  );

  return {
    locationsData: data?.getOrganizationById,
    deliveryLocationsLoading: loading,
    error,
    refetch,
  };
};
