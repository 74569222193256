import { gql, useQuery } from '@apollo/client';
import { i18n } from '../i18n';
import { useEffect } from 'react';
import moment from 'moment';
import { GetMyLanguageTagQuery } from 'generated/graphql';

export const GET_LANGUAGE_TAG_QUERY = gql`
  query GetMyLanguageTag {
    getMyLanguageTag {
      languageTag
    }
  }
`;

export const useUserLanguage = (): { language: boolean } => {
  const result = useQuery<GetMyLanguageTagQuery>(GET_LANGUAGE_TAG_QUERY);
  const lang = result.data?.getMyLanguageTag.languageTag ?? 'en';
  const language = lang !== i18n.language;

  useEffect(() => {
    if (language) {
      void Promise.resolve()
        .then(() => lang !== 'en' && import(`moment/locale/${lang}`))
        .then(() => i18n.changeLanguage(lang))
        .then(() => moment.locale(lang));
    }
  });

  return { language };
};
