import { gql, useQuery } from '@apollo/client';
import { useAuthContext } from 'contexts';
import { DeliveryZone, GetDeliveryZonesQuery } from 'generated/graphql';

export const DELIVERY_ZONES_QUERY = gql`
  query GetDeliveryZones {
    getDeliveryZones {
      id
      space {
        name
        id
      }
      name
      location {
        name
        id
      }
      level {
        name
        id
      }
    }
  }
`;

export const useGetDeliveryZones = () => {
  const { currentOrg } = useAuthContext();

  const { data, loading, error, refetch } = useQuery<GetDeliveryZonesQuery>(
    DELIVERY_ZONES_QUERY,
    {
      skip: !currentOrg,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-and-network',
    }
  );

  return {
    deliveryZones: data?.getDeliveryZones as DeliveryZone[],
    deliveryZonesLoading: loading || data === undefined,
    deliveryZonesError: error,
    refetchDeliveryZones: refetch,
  };
};
