import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { CircleXSolid } from '@robinpowered/icons';
import { Button, Typography, theme } from '@robinpowered/ui-kit';

type DeliveryConfirmationProps = {
  onClick: () => void;
};

export const DeliveryConfirmationError = ({
  onClick,
}: DeliveryConfirmationProps): JSX.Element => {
  const { t } = useTranslation('deliveryConfirmation');
  const { token } = theme.useToken();

  return (
    <MessageWrapper>
      <CircleXSolid color={token.red} size={72} />
      <StyledHeading>{t('error_heading')}</StyledHeading>
      <StyledBody size="base">{t('error_body')}</StyledBody>
      <Button type="primary" onClick={onClick}>
        {t('retry')}
      </Button>
    </MessageWrapper>
  );
};

const StyledHeading = styled(Typography.Title)`
  display: block;
  margin-top: var(--Space-Margin-paddingLG, 24px);
  color: var(--Components-Result-Global-colorTextHeading, rgba(0, 0, 0, 0.95));
  text-align: center;
  font-size: var(--Components-Result-Global-fontSizeHeading3, 22px);
  font-weight: var(--Typography-Font-Weight-fontWeightStrong, 500);
  line-height: var(--Components-Result-Global-lineHeightHeading3, 28px);
`;

const StyledBody = styled(Typography.Text)`
  display: block;
  margin-top: var(--Space-Margin-marginXS, 8px);
  margin-bottom: var(--Space-Margin-paddingLG, 24px);
  color: var(
    --Components-Result-Global-colorTextDescription,
    rgba(0, 0, 0, 0.55)
  );
  font-size: var(--Components-Result-Global-fontSize, 14px);
  font-weight: var(--Typography-Font-Weight-fontWeightNormal, 400);
  line-height: var(--Components-Result-Global-lineHeight, 22px);
  text-align: center;
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
