import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { DeliveryZone } from 'generated/graphql';
import {
  useCreateDeliveryZone,
  useGetDeliveriesPermittedLocations,
  useUpdateDeliveryZone,
} from 'pages/DeliveryZonesPage/hooks';
import { DELIVERY_ZONES_QUERY, useGetDeliveryZones } from '../hooks';
import { message } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { Sentry } from 'lib/sentry';
import { useDeleteDeliveryZone } from 'pages/DeliveryZonesPage/hooks/useDeleteDeliveryZone';

export type RenderedTableDataType = {
  key: string;
  building?: string | JSX.Element;
  floor: string | JSX.Element;
  deliveryZone: string | JSX.Element;
  space: string | JSX.Element;
  locationId?: string;
  actions?: JSX.Element;
};

interface OptionType {
  value: string | null;
  label: string;
}

type DeliveryZonesContextValue = {
  deliveryZones?: DeliveryZone[];
  tableData?: RenderedTableDataType[];
  deliveryZoneLocations?: OptionType[];
  deliveryZoneLevels?: OptionType[];
  deliveryZoneSpaces?: OptionType[];
  zoneName: string;
  zoneLocation?: string | null;
  zoneLevel: string | null;
  zoneSpace: string | null;
  isLoading: boolean;
  isAddingDeliveryZone: boolean;
  recordKey: string | null;
  deliveryZoneToHandle?: DeliveryZone;
  isDeleteModalOpen: boolean;
  isDeleting: boolean;
  setIsDeleting: (value: boolean) => void;
  setIsDeleteModalOpen: (value: boolean) => void;
  setZoneName: (value: string) => void;
  setZoneLocation: (value?: string | null) => void;
  setZoneLevel: (value: string | null) => void;
  setZoneSpace: (value: string | null) => void;
  handleAddDeliveryZone: () => void;
  handleCreateDeliveryZone: () => void;
  handleUpdateDeliveryZone: () => void;
  handleDeleteDeliveryZone: () => void;
  handleCancelCreate: () => void;
  handleCancelDelete: () => void;
  isPermittedLocation: (value: string) => boolean | undefined;
  isEditing: (record: RenderedTableDataType) => boolean;
  setRecordKey: (value: string | null) => void;
  onCancelEdit: () => void;
};

const DeliveryZonesContext = createContext<DeliveryZonesContextValue>({
  deliveryZones: [],
  tableData: [],
  deliveryZoneLocations: [{ value: null, label: '' }],
  deliveryZoneLevels: [{ value: null, label: '' }],
  deliveryZoneSpaces: [{ value: null, label: '' }],
  isAddingDeliveryZone: false,
  zoneName: '',
  zoneLocation: '',
  zoneLevel: null,
  zoneSpace: null,
  isLoading: false,
  recordKey: '',
  deliveryZoneToHandle: { createdAt: '', id: '', name: '', tenantId: '' },
  isDeleteModalOpen: false,
  isDeleting: false,
  setIsDeleting: () => {},
  setIsDeleteModalOpen: () => {},
  setZoneName: () => {},
  setZoneLocation: () => {},
  setZoneLevel: () => {},
  setZoneSpace: () => {},
  handleAddDeliveryZone: () => {},
  handleCreateDeliveryZone: () => {},
  handleUpdateDeliveryZone: () => {},
  handleDeleteDeliveryZone: () => {},
  handleCancelCreate: () => {},
  handleCancelDelete: () => {},
  isPermittedLocation: () => false,
  isEditing: () => false,
  setRecordKey: () => {},
  onCancelEdit: () => {},
});

export const DeliveryZonesContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { t } = useTranslation('deliveryZonesTable');
  const { deliveryZones, deliveryZonesLoading } = useGetDeliveryZones();
  const { permittedLocations } = useGetDeliveriesPermittedLocations();
  const [zoneName, setZoneName] = useState<string>('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [zoneLevel, setZoneLevel] = useState<string | null>(null);
  const [zoneLocation, setZoneLocation] = useState<string | null | undefined>(
    null
  );
  const [zoneSpace, setZoneSpace] = useState<string | null>(null);
  const [deliveryZoneLevels, setDeliveryZoneLevels] = useState<OptionType[]>(
    []
  );
  const [deliveryZoneSpaces, setDeliveryZoneSpaces] = useState<OptionType[]>(
    []
  );
  const [dataSource, setDataSource] = useState<RenderedTableDataType[]>([]);
  const [recordKey, setRecordKey] = useState<string | null>(null);
  const [isAddingDeliveryZone, setIsAddingDeliveryZone] = useState(false);
  const deliveryZoneToHandle = useMemo(
    () => deliveryZones?.find((zone) => zone.id === recordKey),
    [deliveryZones, recordKey]
  );
  const [messageApi, contextHolder] = message.useMessage();
  const [createDeliveryZone, { loading: createDeliveryZoneLoading }] =
    useCreateDeliveryZone();
  const [updateDeliveryZone, { loading: updateDeliveryZoneLoading }] =
    useUpdateDeliveryZone();
  const [deleteDeliveryZone, { loading: deleteDeliveryZoneLoading }] =
    useDeleteDeliveryZone();
  const isEditing = (record: RenderedTableDataType) => record.key === recordKey;
  const permittedLocationsIds = useMemo(
    () => permittedLocations?.canManage.map((location) => location.id),
    [permittedLocations]
  );
  const isPermittedLocation = (id: string) =>
    permittedLocationsIds?.includes(id);
  const handleClearFields = () => {
    setZoneName('');
    setZoneLocation(null);
    setZoneLevel(null);
    setZoneSpace(null);
    setDeliveryZoneLevels([]);
    setDeliveryZoneSpaces([]);
  };

  const onCancelEdit = () => {
    setRecordKey(null);
    handleClearFields();
  };

  const handleCancelCreate = () => {
    const sourceCopy = [...dataSource];
    sourceCopy.shift();
    setDataSource(sourceCopy);
    handleClearFields();
    setIsAddingDeliveryZone(false);
  };
  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
    setIsDeleting(false);
    setRecordKey(null);
  };
  const handleUpdateDeliveryZone = async () => {
    const variables = {
      id: recordKey || '',
      input: {
        name: zoneName,
        locationId: zoneLocation || '',
        levelId: zoneLevel,
        spaceId: zoneSpace,
      },
    };

    await updateDeliveryZone({
      variables,
      onCompleted: async () => {
        setRecordKey(null);
        await messageApi.success(t('edit_success'));
      },
      refetchQueries: [{ query: DELIVERY_ZONES_QUERY }],
      onError: async (error: ApolloError) => {
        await messageApi.error(t('edit_error'));
        Sentry.captureMessage(error.message);
      },
    });
  };
  const handleCreateDeliveryZone = async () => {
    const variables = {
      input: {
        name: zoneName,
        locationId: zoneLocation || '',
        levelId: zoneLevel || null,
        spaceId: zoneSpace || null,
      },
    };

    await createDeliveryZone({
      variables,
      onCompleted: async () => {
        handleClearFields();
        setIsAddingDeliveryZone(false);
        await messageApi.success(t('create_success'));
      },
      refetchQueries: [{ query: DELIVERY_ZONES_QUERY }],
      onError: async (error: ApolloError) => {
        await messageApi.error(t('create_error'));
        Sentry.captureMessage(error.message);
      },
    });
  };
  const handleDeleteDeliveryZone = async () => {
    const variables = {
      id: deliveryZoneToHandle?.id || '',
    };

    await deleteDeliveryZone({
      variables,
      onCompleted: async () => {
        handleCancelDelete();
        await messageApi.success(t('delete_success'));
      },
      refetchQueries: [{ query: DELIVERY_ZONES_QUERY }],
      onError: async (error: ApolloError) => {
        await messageApi.error(t('delete_error'));
        Sentry.captureMessage(error.message);
      },
    });
  };
  const deliveryZoneLocations = useMemo(() => {
    return permittedLocations?.canManage?.map((location) => {
      return {
        value: location.id,
        label: location.name as string,
      };
    });
  }, [permittedLocations]);
  const filteredLocation = useMemo(
    () =>
      permittedLocations?.canManage?.find(
        (location) => location.id === zoneLocation
      ),
    [permittedLocations, zoneLocation]
  );
  const filteredLevel = useMemo(
    () => filteredLocation?.levels?.find((level) => level.id === zoneLevel),
    [filteredLocation, zoneLevel]
  );

  useEffect(() => {
    const levelsArr =
      filteredLocation?.levels?.map((level) => {
        return {
          value: level.id,
          label: level.name as string,
        };
      }) || [];

    setDeliveryZoneLevels([{ value: '', label: '-' }, ...levelsArr]);
  }, [filteredLocation]);
  useEffect(() => {
    const spacesArr =
      filteredLevel?.spaces?.map((space) => {
        return {
          value: space.id,
          label: space.name as string,
        };
      }) || [];

    setDeliveryZoneSpaces([{ value: '', label: '-' }, ...spacesArr]);
  }, [filteredLevel]);
  useEffect(() => {
    const tableData = deliveryZones?.map(
      (zone): RenderedTableDataType => ({
        key: zone.id,
        deliveryZone: zone.name,
        floor: zone.level?.name || '-',
        building: zone.location?.name as string,
        space: zone.space?.name || '-',
        locationId: zone.location?.id,
      })
    );
    setDataSource(tableData);
  }, [deliveryZones, permittedLocations]);

  const handleAddDeliveryZone = () => {
    const newData: RenderedTableDataType = {
      key: '',
      deliveryZone: '',
      building: '',
      floor: '',
      space: '',
      locationId: '',
    };
    setDataSource([newData, ...dataSource]);
    setIsAddingDeliveryZone(true);
  };

  return (
    <>
      {contextHolder}
      <DeliveryZonesContext.Provider
        value={{
          isLoading:
            deliveryZonesLoading ||
            createDeliveryZoneLoading ||
            updateDeliveryZoneLoading ||
            deleteDeliveryZoneLoading,
          deliveryZones,
          tableData: dataSource,
          deliveryZoneLocations,
          deliveryZoneLevels,
          deliveryZoneSpaces,
          zoneName,
          zoneLocation,
          zoneLevel,
          zoneSpace,
          isAddingDeliveryZone,
          recordKey,
          deliveryZoneToHandle,
          isDeleteModalOpen,
          isDeleting,
          onCancelEdit,
          setIsDeleting,
          setIsDeleteModalOpen,
          setZoneName,
          setZoneLocation,
          setZoneLevel,
          setZoneSpace,
          handleAddDeliveryZone,
          handleCreateDeliveryZone,
          handleUpdateDeliveryZone,
          handleDeleteDeliveryZone,
          handleCancelCreate,
          handleCancelDelete,
          isPermittedLocation,
          isEditing,
          setRecordKey,
        }}
      >
        {children}
      </DeliveryZonesContext.Provider>
    </>
  );
};

export const useDeliveryZonesContext = (): DeliveryZonesContextValue => {
  return useContext(DeliveryZonesContext);
};
