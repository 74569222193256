import { Form, Input, Select } from '@robinpowered/ui-kit';
import {
  RenderedTableDataType,
  useDeliveryZonesContext,
} from 'pages/DeliveriesPage/contexts';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface EditableCellProps {
  title: React.ReactNode;
  isCreating: boolean;
  isEditing: boolean;
  dataIndex: keyof RenderedTableDataType;
  record: RenderedTableDataType;
  inputType: string;
  handleSave: (record: RenderedTableDataType) => void;
}

const MAX_CHARACTER_LENGTH = 88;

export const EditableCell: React.FC<
  React.PropsWithChildren<EditableCellProps>
> = ({
  isCreating,
  isEditing,
  dataIndex,
  title,
  inputType,
  record,
  children,
  ...restProps
}) => {
  const { t } = useTranslation('deliveryZonesTable');
  const {
    deliveryZoneLevels,
    deliveryZoneLocations,
    deliveryZoneSpaces,
    zoneName,
    zoneLocation,
    zoneLevel,
    zoneSpace,
    deliveryZoneToHandle,
    isDeleting,
    setZoneName,
    setZoneLocation,
    setZoneLevel,
    setZoneSpace,
  } = useDeliveryZonesContext();

  useEffect(
    () => {
      if (isCreating) {
        setZoneLocation(deliveryZoneLocations?.[0]?.value);
      }

      if (isEditing) {
        setZoneLocation(deliveryZoneToHandle?.location?.id);
        setZoneName(deliveryZoneToHandle?.name || '');
        setZoneLevel(deliveryZoneToHandle?.level?.id || null);
        setZoneSpace(deliveryZoneToHandle?.space?.id || null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deliveryZoneLocations, isCreating, isEditing, deliveryZoneToHandle]
  );

  const selectOptions = useMemo(() => {
    let displayedSelectOptions;
    switch (dataIndex) {
      case 'floor':
        displayedSelectOptions = (
          <Select
            options={deliveryZoneLevels}
            defaultValue={record.floor.toString() || '-'}
            value={zoneLevel}
            onChange={(value) => {
              setZoneLevel(value);
              setZoneSpace(null);
            }}
            placeholder={t('placeholders.floor')}
          />
        );
        break;
      case 'space':
        displayedSelectOptions = (
          <Select
            options={deliveryZoneSpaces}
            defaultValue={record.space.toString() || '-'}
            value={zoneSpace}
            onChange={(value) => setZoneSpace(value)}
            placeholder={t('placeholders.space')}
          />
        );
        break;
      default:
        displayedSelectOptions = (
          <Select
            options={deliveryZoneLocations}
            defaultValue={
              isEditing
                ? record.building?.toString()
                : deliveryZoneLocations?.[0]?.value
            }
            value={zoneLocation}
            onChange={(value) => {
              setZoneLocation(value);
              setZoneLevel(null);
              setZoneSpace(null);
            }}
            placeholder={t('placeholders.building')}
          />
        );
    }

    return displayedSelectOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataIndex,
    deliveryZoneLevels,
    deliveryZoneSpaces,
    deliveryZoneLocations,
    zoneLevel,
    zoneLocation,
    zoneSpace,
    isEditing,
  ]);

  const inputNode =
    inputType === 'input' ? (
      <Input
        defaultValue={record.deliveryZone.toString()}
        value={zoneName}
        onChange={(event) => setZoneName(event.target.value)}
        maxLength={MAX_CHARACTER_LENGTH}
        placeholder={t('placeholders.delivery_zone')}
      />
    ) : (
      selectOptions
    );

  return (
    <td {...restProps}>
      {isCreating || (isEditing && !isDeleting) ? (
        <Form.Item style={{ marginBottom: 0 }}>{inputNode}</Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
