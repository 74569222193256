import styled from '@emotion/styled';
import { Select } from '@robinpowered/ui-kit';
import { DeliveryStatus } from 'generated/graphql';
import { DeliveryStatusTag } from './DeliveryStatusTag';

type DeliveryStatusSelecttProps = {
  status: DeliveryStatus;
  onChange: (status: unknown) => void;
  variant?: 'borderless' | 'outlined' | 'filled';
};

/**
 * Dropdown delivery status selector that allows for a custom onChange handler.
 */
export const DeliveryStatusSelect = ({
  status,
  onChange,
  variant = 'outlined',
}: DeliveryStatusSelecttProps) => (
  <StyledSelect
    value={status}
    defaultValue={status}
    variant={variant}
    onChange={onChange}
  >
    {(Object.keys(DeliveryStatus) as Array<keyof typeof DeliveryStatus>).map(
      (s) => (
        <Select.Option key={s} value={DeliveryStatus[s]}>
          <DeliveryStatusTag status={DeliveryStatus[s]} />
        </Select.Option>
      )
    )}
  </StyledSelect>
);

const StyledSelect = styled(Select)`
  width: 100%;
`;
