import { gql, MutationTuple, useMutation } from '@apollo/client';
import {
  DeleteDeliveryZoneMutation,
  DeleteDeliveryZoneMutationVariables,
} from 'generated/graphql';

export const DELETE_DELIVERY_ZONE = gql`
  mutation DeleteDeliveryZone($id: ID!) {
    deleteDeliveryZone(id: $id)
  }
`;

export const useDeleteDeliveryZone = (): MutationTuple<
  DeleteDeliveryZoneMutation,
  DeleteDeliveryZoneMutationVariables
> => {
  return useMutation<
    DeleteDeliveryZoneMutation,
    DeleteDeliveryZoneMutationVariables
  >(DELETE_DELIVERY_ZONE);
};
