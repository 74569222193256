import { gql, MutationTuple, useMutation } from '@apollo/client';
import {
  DeliveryTableUpdateDeliveryMutation,
  DeliveryTableUpdateDeliveryMutationVariables,
} from 'generated/graphql';

export const UPDATE_DELIVERY = gql`
  mutation DeliveryTableUpdateDelivery($id: ID!, $input: UpdateDeliveryInput!) {
    updateDelivery(id: $id, input: $input) {
      id
    }
  }
`;

export const useUpdateDelivery = (): MutationTuple<
  DeliveryTableUpdateDeliveryMutation,
  DeliveryTableUpdateDeliveryMutationVariables
> => {
  return useMutation<
    DeliveryTableUpdateDeliveryMutation,
    DeliveryTableUpdateDeliveryMutationVariables
  >(UPDATE_DELIVERY);
};
