import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

export const DocumentHead = (): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <Helmet>
      <title>{t('default_page_title')}</title>
    </Helmet>
  );
};
