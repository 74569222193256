import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import {
  Body05Styles,
  ButtonV4 as Button,
  Colors,
  Heading01Styles,
  Heading06,
  Skeleton,
} from '@robinpowered/design-system';
import UpgradePlanImage from './img/upgrade_deliveries_vm_license.png';
import Lock from '@robinpowered/icons/LockOuline';
import Chat from '@robinpowered/icons/v2/Chat';
import { useApolloContext, useAuthContext } from 'contexts';
import { gql, useQuery } from '@apollo/client';
import { PageMessage } from 'components';

const { REACT_APP_DASHBOARD_URL } = process.env;
const dashboardUrl =
  REACT_APP_DASHBOARD_URL || 'https://dashboard.staging.robinpowered.com';

const BILLING_PERMISSION_QUERY = gql`
  query HasBillingPermissionForCurrentTenantQuery {
    getCurrentUserAndOrgs {
      userId
      hasBillingPermissionForCurrentTenant
    }
  }
`;

export const UpgradePlan: FC = () => {
  // TODO: Move this to its own JSON translations file
  const { t } = useTranslation('deliveriesPage');

  const { currentOrg } = useAuthContext();

  const { tenantId } = useApolloContext();

  const {
    data: billingPermissionResult,
    loading: billingPermissionLoading,
    error: billingPermissionError,
  } = useQuery(BILLING_PERMISSION_QUERY);

  const hasBillingPermissionForCurrentTenant =
    billingPermissionResult?.getCurrentUserAndOrgs
      ?.hasBillingPermissionForCurrentTenant;

  const showLoading = billingPermissionLoading || !tenantId || !currentOrg;
  const showError = !showLoading && billingPermissionError;

  const handleRedirect = () => {
    // slug won't be undefined to the user, because we wait for it to load
    // see showLoading, above
    window.location.href = `${dashboardUrl}/${currentOrg?.slug}/settings/billing`;
  };

  const renderBillingButton = () => {
    if (hasBillingPermissionForCurrentTenant) {
      return (
        <UpgradeButton
          variant="primary"
          onClick={handleRedirect}
          label={t('upgrade_promo.upgrade_button_text')}
          iconRight={ChatIcon}
        ></UpgradeButton>
      );
    }

    return (
      <RestrictedPermissionsButtonWrapper>
        <RestrictedUpgradeButton
          variant="primary"
          disabled={true}
          onClick={handleRedirect}
          label={t('upgrade_promo.restricted_upgrade_button_text')}
        />
        <OpenIntercomButton
          variant="tertiary"
          iconLeft={ChatIcon}
          label={t('upgrade_promo.open_intercom_button_text')}
          // TODO: implement this in a future PR
          onClick={() => ({})}
        />
      </RestrictedPermissionsButtonWrapper>
    );
  };
  if (showLoading) {
    return (
      <LoadingWrapper>
        <Skeleton height="18px" width="500px" mb="18px"></Skeleton>
      </LoadingWrapper>
    );
  } else if (showError) {
    return (
      <PageMessage
        title={t('upgrade_promo.error.title')}
        message={t('upgrade_promo.error.message')}
      />
    );
  } else {
    return (
      <UpgradePlanWrapper>
        <Container>
          <UnlockContainer>
            <UnlockMessage>
              <Lock size={18} color={Colors.Maroon100}></Lock>
              <Heading06>{t('upgrade_promo.upgrade_unlock')}</Heading06>
            </UnlockMessage>
          </UnlockContainer>
          <Title>{t('upgrade_promo.title')}</Title>
          <BodyContent>{t('upgrade_promo.body')}</BodyContent>

          {renderBillingButton()}
        </Container>
        <UpgradeImage src={UpgradePlanImage} alt="changeme!" />
      </UpgradePlanWrapper>
    );
  }
};

const LoadingWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 500px;
  margin-bottom: 48px;
`;

const UnlockContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const UnlockMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px 12px;

  width: 170px;
  height: 32px;

  background: ${Colors.Tan30};
  border-radius: 50px;
`;

const Title = styled.h1`
  ${Heading01Styles}
  font-weight: 500;
  margin-top: 16px;
  margin-bottom: 4px;
  text-align: center;
`;

const BodyContent = styled.div`
  ${Body05Styles}
  text-align: center;
`;

const UpgradePlanWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 0%;
  margin-top: 48px;
`;

const UpgradeImage = styled.img`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

const ChatIcon = styled(Chat)`
  margin-left: 10px;
`;

const UpgradeButton = styled(Button)`
  margin: 16px auto 0 auto;
`;

const RestrictedPermissionsButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const RestrictedUpgradeButton = styled(Button)`
  margin: 16px auto 0 auto;
  background-color: ${Colors.Maroon50};
  color: ${Colors.White0};
`;

const OpenIntercomButton = styled(Button)`
  display: flex;
  height: 32px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
