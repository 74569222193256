import { useMutation, gql, MutationTuple } from '@apollo/client';
import {
  DeliveryDrawerCreateDeliveryMutation,
  DeliveryDrawerCreateDeliveryMutationVariables,
} from 'generated/graphql';

export const CREATE_DELIVERY = gql`
  mutation DeliveryDrawerCreateDelivery($input: CreateDeliveryInput!) {
    createDelivery(input: $input) {
      id
    }
  }
`;

export const useCreateDelivery = (): MutationTuple<
  DeliveryDrawerCreateDeliveryMutation,
  DeliveryDrawerCreateDeliveryMutationVariables
> => {
  return useMutation<
    DeliveryDrawerCreateDeliveryMutation,
    DeliveryDrawerCreateDeliveryMutationVariables
  >(CREATE_DELIVERY);
};
