import styled from '@emotion/styled';
import { Colors, Column } from '@robinpowered/design-system';
import { Button, Modal, Row, theme, Typography } from '@robinpowered/ui-kit';
import { useDeliveryZonesContext } from 'pages/DeliveriesPage/contexts';
import { useTranslation } from 'react-i18next';
import { DeliveryZonesTable } from './components';
import EmergencySolid from '@robinpowered/icons/EmergencySolid';
import { useGetDeliveriesPermittedLocations } from './hooks';

export const DeliveryZonesPage = () => {
  const { t } = useTranslation('deliveryZonesPage');
  const {
    handleAddDeliveryZone,
    handleCancelDelete,
    handleDeleteDeliveryZone,
    isDeleteModalOpen,
    isLoading,
    isAddingDeliveryZone,
    recordKey,
    deliveryZoneToHandle,
  } = useDeliveryZonesContext();
  const { token } = theme.useToken();
  const { permittedLocations, permittedLocationsLoading } =
    useGetDeliveriesPermittedLocations();
  const isDisabled =
    isLoading ||
    isAddingDeliveryZone ||
    !!recordKey ||
    permittedLocationsLoading;

  const ModalTitle = (
    <Row>
      <Row>
        <IconWrapper>
          <EmergencySolid size={18} color={token.colorError} />
        </IconWrapper>
        <div>
          <Typography.Text>{t('delete_modal.title')}</Typography.Text>&nbsp;
        </div>
      </Row>
      <Row>
        <Typography.Text>
          {t('delete_modal.zone_to_delete', {
            zone: deliveryZoneToHandle?.name,
          })}
        </Typography.Text>
      </Row>
    </Row>
  );

  return (
    <ScrollWrapper>
      <DeliveryZonesWrapper>
        <CardTitleWrapper>
          <Column>
            <CardTitle>{t('tableCard.title')}</CardTitle>
            <CardByline>{t('tableCard.byline')}</CardByline>
          </Column>
          {permittedLocations && permittedLocations?.canManage.length > 0 && (
            <Button
              type="primary"
              onClick={handleAddDeliveryZone}
              disabled={isDisabled}
            >
              {t('tableCard.header_button')}
            </Button>
          )}
        </CardTitleWrapper>
        <DeliveryZonesTable />
      </DeliveryZonesWrapper>
      <Modal
        open={isDeleteModalOpen}
        maskClosable
        onCancel={handleCancelDelete}
        title={ModalTitle}
        okText={t('delete_modal.confirmText')}
        okType="danger"
        okButtonProps={{ type: 'primary' }}
        onOk={handleDeleteDeliveryZone}
        confirmLoading={isLoading}
      >
        <ModalContent>{t('delete_modal.content')}</ModalContent>
      </Modal>
    </ScrollWrapper>
  );
};

const ScrollWrapper = styled.div`
  background-color: ${Colors.Gray5};
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 32px;
`;

const DeliveryZonesWrapper = styled.div`
  background-color: ${Colors.White0};
  border-radius: 8px;
  border: 1px solid ${Colors.Gray10};
  padding: var(--Space-Margin-marginLG, 24px);
`;

const CardTitle = styled(Typography.Text)`
  font-size: var(--Typography-Font-Size-fontSizeHeading1, 30px) !important;
`;

const CardByline = styled(Typography.Text)`
  color: var(
    --Components-Typography-Global-colorTextDescription,
    rgba(0, 0, 0, 0.6)
  );
`;

const CardTitleWrapper = styled(Row)`
  padding-bottom: var(--Space-Margin-marginLG, 24px);
  justify-content: space-between;
  align-items: center;
`;

const ModalContent = styled(Typography.Text)`
  color: var(--Components-Modal-Global-colorText, rgba(0, 0, 0, 0.95));
  font-size: var(--Components-Modal-Global-fontSize, 14px);
  font-style: normal;
  font-weight: var(--Typography-Font-Weight-fontWeightNormal, 400);
  line-height: var(--Components-Modal-Global-lineHeight, 22px);
`;

const IconWrapper = styled.div`
  margin-right: 8px;
`;
