import styled from '@emotion/styled';
import { Body, Flex } from '@robinpowered/design-system';

type PropTypes = {
  title: string;
  message?: string;
};

export const PageMessage = ({ title, message }: PropTypes): JSX.Element => {
  return (
    <Flex
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      paddingBottom="25%"
    >
      <Body.Large>{title}</Body.Large>
      {message && <StyledBody>{message}</StyledBody>}
    </Flex>
  );
};

const StyledBody = styled(Body.Medium)`
  margin-top: 8px;
`;
