import { Recipient } from 'generated/graphql';

export const removeDuplicates = (objects: Recipient[]) => {
  const noNulls = objects.filter((r) => r !== null);
  const ids = noNulls.map(({ id }) => id);
  const filtered = noNulls.filter(
    ({ id }, index) => !ids.includes(id, index + 1)
  );

  return filtered;
};
