import { getUsersByKeyword_getUsersByKeyword } from 'components';
import { Delivery, DeliveryStatus, User } from 'generated/graphql';
import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import { emailRegex } from '../constants';

type Recipient = getUsersByKeyword_getUsersByKeyword | User | null;

type DeliveryEditContextValue = {
  additionalNotes: string;
  deliveryToEdit: Delivery | null;
  deliveryStatus: DeliveryStatus | null;
  deliveryZoneId: string | null;
  recipient: Recipient;
  recipientName: string;
  recipientEmail: string;
  recipientNameError: boolean;
  recipientEmailError: boolean;
  isFormValid: boolean;
  hasFormChanged: boolean;
  showErrors: boolean;
  showCancelDialog: boolean;
  setHasFormChanged: (changed: boolean) => void;
  updateAdditionalNotes: (notes: string) => void;
  setDeliveryToEdit: (id: Delivery | null) => void;
  updateDeliveryStatus: (status: DeliveryStatus) => void;
  updateDeliveryZoneId: (zone: string) => void;
  updateRecipient: (recipient: Recipient) => void;
  updateRecipientName: (name: string) => void;
  updateRecipientEmail: (email: string) => void;
  setShowErrors: (show: boolean) => void;
  setShowCancelDialog: (show: boolean) => void;
  clearValues: () => void;
  handleCloseEditDrawer: () => void;
};

const DeliveryEditContext = createContext<DeliveryEditContextValue>({
  additionalNotes: '',
  deliveryToEdit: null,
  deliveryStatus: null,
  deliveryZoneId: null,
  recipient: null,
  recipientName: '',
  recipientEmail: '',
  recipientNameError: false,
  recipientEmailError: false,
  isFormValid: false,
  hasFormChanged: false,
  showErrors: false,
  showCancelDialog: false,
  setHasFormChanged: () => {},
  updateAdditionalNotes: () => {},
  updateDeliveryStatus: () => {},
  setDeliveryToEdit: () => {},
  updateDeliveryZoneId: () => {},
  updateRecipient: () => {},
  updateRecipientName: () => {},
  updateRecipientEmail: () => {},
  setShowErrors: () => {},
  setShowCancelDialog: () => {},
  clearValues: () => {},
  handleCloseEditDrawer: () => {},
});

export const DeliveryEditContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [hasFormChanged, setHasFormChanged] = useState(false);
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [deliveryToEdit, setDeliveryToEdit] = useState<Delivery | null>(null);
  const [deliveryStatus, setDeliveryStatus] = useState<DeliveryStatus | null>(
    null
  );
  const [deliveryZoneId, setDeliveryZoneId] = useState<string | null>(null);
  const [recipientName, setRecipientName] = useState('');
  const [recipientEmail, setRecipientEmail] = useState('');
  const [recipient, setRecipient] = useState<Recipient>(null);
  const [showErrors, setShowErrors] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const recipientNameError = recipientEmail.length > 0 && !recipientName.length;
  const recipientEmailError =
    (recipientName.length > 0 && !recipientEmail.length) ||
    (recipientEmail.length > 0 && !emailRegex.test(recipientEmail));
  const deliveryZoneIdError = deliveryZoneId === null;
  const isFormValid =
    !recipientNameError && !recipientEmailError && !deliveryZoneIdError;

  // Nuke everthing back to the starting point to avoid any stale data
  const clearValues = () => {
    setHasFormChanged(false);
    setAdditionalNotes('');
    setDeliveryToEdit(null);
    setDeliveryStatus(null);
    setDeliveryZoneId(null);
    setRecipientName('');
    setRecipientEmail('');
    setRecipient(null);
    setShowErrors(false);
    setShowCancelDialog(false);
  };

  const handleCloseEditDrawer = () => {
    if (hasFormChanged) {
      setShowCancelDialog(true);
    } else {
      clearValues();
    }
  };

  const updateDeliveryStatus = (status: DeliveryStatus) => {
    setHasFormChanged(true);
    setDeliveryStatus(status);
  };
  const updateDeliveryZoneId = (id: string) => {
    setHasFormChanged(true);
    setDeliveryZoneId(id);
  };
  const updateRecipient = (recipient: Recipient) => {
    setHasFormChanged(true);
    setRecipient(recipient);
  };
  const updateRecipientName = (name: string) => {
    setHasFormChanged(true);
    setRecipientName(name);
  };
  const updateRecipientEmail = (email: string) => {
    setHasFormChanged(true);
    setRecipientEmail(email);
  };
  const updateAdditionalNotes = (notes: string) => {
    setHasFormChanged(true);
    setAdditionalNotes(notes);
  };

  useEffect(() => {
    if (!deliveryToEdit) return;

    if (!recipient && deliveryToEdit?.recipient?.user) {
      setRecipient(deliveryToEdit.recipient.user);
    }

    setAdditionalNotes(deliveryToEdit.additionalNotes || '');
    setDeliveryStatus(deliveryToEdit.status);
    setDeliveryZoneId(deliveryToEdit.deliveryZone?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryToEdit]);

  useEffect(() => {
    setRecipientName(recipient?.name || '');
    setRecipientEmail(recipient?.primaryEmail?.email || '');
  }, [recipient]);

  return (
    <DeliveryEditContext.Provider
      value={{
        additionalNotes,
        deliveryToEdit,
        deliveryStatus,
        deliveryZoneId,
        recipient,
        recipientName,
        recipientEmail,
        recipientNameError,
        recipientEmailError,
        isFormValid,
        hasFormChanged,
        showErrors,
        showCancelDialog,
        setHasFormChanged,
        setDeliveryToEdit,
        updateAdditionalNotes,
        updateDeliveryStatus,
        updateDeliveryZoneId,
        updateRecipient,
        updateRecipientName,
        updateRecipientEmail,
        setShowErrors,
        setShowCancelDialog,
        handleCloseEditDrawer,
        clearValues,
      }}
    >
      {children}
    </DeliveryEditContext.Provider>
  );
};

export const useDeliveryEditContext = (): DeliveryEditContextValue => {
  return useContext(DeliveryEditContext);
};
