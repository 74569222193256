import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Spin, Typography } from '@robinpowered/ui-kit';

export const DeliveryConfirmationLoading = (): JSX.Element => {
  const { t } = useTranslation('deliveryConfirmation');

  return (
    <MessageWrapper>
      <StyledLoader size="large" />
      <StyledBody size="base">{t('loading_text')}</StyledBody>
    </MessageWrapper>
  );
};

const StyledBody = styled(Typography.Text)`
  display: block;
  color: var(
    --Components-Result-Global-colorTextDescription,
    rgba(0, 0, 0, 0.55)
  );
  font-size: var(--Components-Result-Global-fontSize, 14px);
  font-weight: var(--Typography-Font-Weight-fontWeightNormal, 400);
  line-height: var(--Components-Result-Global-lineHeight, 22px);
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledLoader = styled(Spin)`
  margin-bottom: var(--Space-Margin-paddingLG, 24px);
`;
