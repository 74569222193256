import { useMutation, gql, MutationTuple } from '@apollo/client';
import {
  DeliveryTableDeleteDeliveriesMutation,
  DeliveryTableDeleteDeliveriesMutationVariables,
} from 'generated/graphql';

export const DELETE_DELIVERY = gql`
  mutation DeliveryTableDeleteDeliveries(
    $ids: [ID!]!
    $clientOptions: ClientOptionsInput
  ) {
    deleteDeliveries(ids: $ids, clientOptions: $clientOptions)
  }
`;

export const useDeleteDeliveries = (): MutationTuple<
  DeliveryTableDeleteDeliveriesMutation,
  DeliveryTableDeleteDeliveriesMutationVariables
> => {
  return useMutation<
    DeliveryTableDeleteDeliveriesMutation,
    DeliveryTableDeleteDeliveriesMutationVariables
  >(DELETE_DELIVERY);
};
