import styled from '@emotion/styled';
import robinLogo from '../media/robin-logo.png';
import { useTranslation } from 'react-i18next';
import { Typography } from '@robinpowered/ui-kit';

type DeliveryConfirmationPageWrapperProps = {
  children: React.ReactNode;
};

export const DeliveryConfirmationPageWrapper = ({
  children,
}: DeliveryConfirmationPageWrapperProps) => {
  const { t } = useTranslation('deliveryConfirmation');

  return (
    <PageWrapper>
      <Header>
        <Logo
          aria-label={t('logo_label')}
          src={robinLogo}
          title={t('logo_label')}
        />
      </Header>
      <Content>
        <MessageContainer>{children}</MessageContainer>
      </Content>
      <Footer>
        <FooterLogo
          aria-label={t('logo_label')}
          src={robinLogo}
          title={t('logo_label')}
        />
        <StyledText size="small">{t('footer_copyright')}</StyledText>
        <StyledText size="small">{t('footer_address')}</StyledText>
      </Footer>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  background: var(--Colors-Neutral-Bg-colorBgLayout, #f5f5f5);
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
`;

const Header = styled.header`
  border-bottom: 1px solid var(--stroke-layer-3-gray-30, #cbc9c9);
  background: var(--main-colors-white-white-0, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.02);
  z-index: 1; /* Allow the shadow to show */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 16px;
`;

const MessageContainer = styled.div`
  border-radius: var(--Border-Radius-borderRadius, 4px);
  background: var(--Colors-Neutral-Bg-colorBgContainer, #fff);
  max-width: 725px;
  width: 725px;
  padding: var(--Space-Margin-marginXXL, 32px);
`;

const Footer = styled.footer`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Logo = styled.img`
  max-width: 75px;
  height: auto;
`;

const FooterLogo = styled.img`
  max-width: 105px;
  margin-bottom: 16px;
  height: auto;
`;

const StyledText = styled(Typography.Text)`
  display: block;
`;
