import {
  useRef,
  KeyboardEventHandler,
  KeyboardEvent,
  FocusEventHandler,
} from 'react';
import styled from '@emotion/styled/macro';
import { Input } from '@robinpowered/ui-kit';
import { ApolloError } from '@apollo/client';
import { useClickAway } from 'react-use';
import { UserSelectResults } from './UserSelectResults';
import { getUsersByKeyword_getUsersByKeyword } from './__generated__/getUsersByKeyword';
import { useKeyboardNavigation } from './useKeyboardNavigation';

const HTML_MENU_ID = 'user-select-results';

type PropTypes = {
  loading: boolean;
  value: string;
  users: null | getUsersByKeyword_getUsersByKeyword[];
  error?: ApolloError;
  formError?: boolean;
  errorText?: string;
  placeholder?: string;
  showResults: boolean;
  showErrors?: boolean;
  onChange: (value: string) => void;
  onBlur: FocusEventHandler<HTMLSpanElement>;
  onKeyDown: KeyboardEventHandler<HTMLInputElement>;
  onFocus: () => void;
  onSelectUser: (user: getUsersByKeyword_getUsersByKeyword) => void;
  onClickAway: () => void;
};

export const UserSelect = ({
  loading,
  value,
  users,
  error,
  placeholder,
  showResults,
  errorText,
  onSelectUser,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  onClickAway,
  showErrors,
}: PropTypes): JSX.Element => {
  const containerRef = useRef(null);
  useClickAway(containerRef, onClickAway);
  const { moveThroughMenu, focusedIndex } = useKeyboardNavigation(
    showResults,
    users
  );

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    onKeyDown(event);

    if (event.key === 'Escape' && showResults) {
      // Close menu on esc
      onClickAway();
      event.stopPropagation();
      return;
    }
    if (event.key === 'Enter' && focusedIndex !== null && users) {
      // Select a user on enter
      onSelectUser(users[focusedIndex]);
      return;
    }
    if (users) {
      // Move up/down through menu items
      moveThroughMenu(event.key);
    }
  };

  return (
    <Container
      ref={containerRef}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={handleKeyDown}
    >
      <Input
        name="userId"
        placeholder={placeholder}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        status={showErrors && !!errorText ? 'error' : undefined}
      />
      {showResults && (
        <UserSelectResults
          id={HTML_MENU_ID}
          loading={loading}
          error={error}
          users={users}
          focusedIndex={focusedIndex}
          onSelectUser={onSelectUser}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  position: relative;
`;
