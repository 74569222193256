import { message } from '@robinpowered/ui-kit';
import { DeliveryStatus } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { useDeliveriesTableContext } from '../contexts';
import { Sentry } from 'lib/sentry';
import { ApolloError } from '@apollo/client';
import { DeliveryStatusSelect } from './DeliveryStatusSelect';

type DeliveryStatusSelectInlineProps = {
  id: string;
  status: DeliveryStatus;
};

/**
 * Dropdown delivery status selector that is used for one off inline status updates
 * with a corresponding API call.
 */
export const DeliveryStatusSelectInline = ({
  id,
  status,
}: DeliveryStatusSelectInlineProps): JSX.Element => {
  const { updateDelivery, refetchDeliveries } = useDeliveriesTableContext();
  const [messageApi, contextHolder] = message.useMessage();
  const { t } = useTranslation('deliveriesTable');

  const onStatusChange = (status: unknown) =>
    updateDelivery({
      variables: { id, input: { status: status as DeliveryStatus } },
      onCompleted: async () => {
        await Promise.all([
          messageApi.success(t('status_update_dialog.success')),
          refetchDeliveries(),
        ]);
      },
      onError: async (error: ApolloError) => {
        Sentry.captureMessage(
          `Error update status of delivery with ID: ${id}: ${error.message}`,
          'error'
        );
        await messageApi.error(t('status_update_dialog.error'));
      },
    });

  return (
    <>
      {contextHolder}

      <DeliveryStatusSelect
        status={status}
        onChange={onStatusChange}
        variant="borderless"
      />
    </>
  );
};
