import { useTranslation } from 'react-i18next';
import { TableColumnsType, TableColumnType } from '@robinpowered/ui-kit';
import {
  RenderedTableDataType,
  useDeliveryZonesContext,
} from 'pages/DeliveriesPage/contexts';
import { TableActionButtons } from '../components';

type EditableTableColumnsType = (TableColumnType<RenderedTableDataType> & {
  editable?: boolean;
  dataIndex: string;
})[];

export const useDeliveryZonesTableData = () => {
  const { t } = useTranslation('deliveryZonesTable');
  const { setRecordKey, isEditing, onCancelEdit, isPermittedLocation } =
    useDeliveryZonesContext();

  // Note: at least one column should be left without a width to allow the table to remain fluid
  const defaultColumns: EditableTableColumnsType = [
    {
      title: t('headers.delivery_zone'),
      dataIndex: 'deliveryZone',
      key: 'deliveryZone',
      width: 200,
      editable: true,
    },
    {
      title: t('headers.building'),
      dataIndex: 'building',
      key: 'building',
      width: 240,
      editable: true,
    },
    {
      title: t('headers.floor'),
      dataIndex: 'floor',
      key: 'floor',
      width: 200,
      editable: true,
    },
    {
      title: t('headers.space'),
      dataIndex: 'space',
      key: 'space',
      width: 200,
      editable: true,
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: 200,
      render: (_, record) => {
        if (!record.key || isPermittedLocation(record.locationId as string)) {
          return (
            <TableActionButtons
              createOrEditMode={!record.key || isEditing(record)}
              setRecordKey={() => setRecordKey(record.key)}
              onCancelEdit={onCancelEdit}
            />
          );
        }
      },
    },
  ];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: RenderedTableDataType) => ({
        record,
        inputType: col.dataIndex === 'deliveryZone' ? 'input' : 'select',
        dataIndex: col.dataIndex,
        title: col.title,
        isCreating: !record.key,
        isEditing: isEditing(record),
      }),
    };
  });

  return {
    columns: columns as TableColumnsType,
  };
};
