import { Pagination } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import { useDeliveriesTableContext } from '../contexts';
import styled from '@emotion/styled';

export const DeliveriesTablePagination = (): JSX.Element => {
  const { t } = useTranslation('deliveriesTable');
  const {
    currentPage,
    setCurrentPage,
    itemsPerPage,
    setItemsPerPage,
    totalItems,
  } = useDeliveriesTableContext();

  return (
    <PaginationWrapper>
      <Pagination
        current={currentPage}
        total={totalItems}
        showTotal={(total) => t('pagination.total', { count: total })}
        pageSize={itemsPerPage}
        onChange={(event) => setCurrentPage(event)}
        onShowSizeChange={(_, pageSize) => {
          setItemsPerPage(pageSize);
        }}
      />
    </PaginationWrapper>
  );
};

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;
