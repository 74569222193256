import { gql } from '@apollo/client';
import { useAuthContext } from 'contexts';
import { useGetDeliveryIdsQuery } from 'generated/graphql';

export const DELIVERY_IDS_QUERY = gql`
  query GetDeliveryIds(
    $filters: DeliveryFilterOptions
    $limit: Int
    $offset: Int
  ) {
    getDeliveries(filters: $filters, limit: $limit, offset: $offset) {
      id
    }
  }
`;

export const useGetDeliveryIds = (searchTerm = '', limit = 200, offset = 0) => {
  const { currentOrg } = useAuthContext();
  const { data, loading, error, refetch } = useGetDeliveryIdsQuery({
    skip: !currentOrg || searchTerm === '',
    variables: {
      filters: { idContains: searchTerm },
      limit,
      offset,
    },
  });

  return {
    deliveryIds: data?.getDeliveries?.map((d) => d.id) || [],
    deliveryIdsLoading: loading,
    deliveryIdsError: error,
    refetchDeliveryIds: refetch,
  };
};
