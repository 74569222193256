import { Delivery } from 'generated/graphql';
import { createContext, useContext, FC, ReactNode, useState } from 'react';

type DeliveryDetailsContextValue = {
  selectedDelivery: Delivery | null;
  setSelectedDelivery: (id: Delivery | null) => void;
};

const DeliveryDetailsContext = createContext<DeliveryDetailsContextValue>({
  selectedDelivery: null,
  setSelectedDelivery: () => {},
});

export const DeliveryDetailsContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [selectedDelivery, setSelectedDelivery] = useState<Delivery | null>(
    null
  );

  return (
    <DeliveryDetailsContext.Provider
      value={{
        selectedDelivery,
        setSelectedDelivery,
      }}
    >
      {children}
    </DeliveryDetailsContext.Provider>
  );
};

export const useDeliveryDetailsContext = (): DeliveryDetailsContextValue => {
  return useContext(DeliveryDetailsContext);
};
