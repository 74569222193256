/* eslint-disable @shopify/jsx-no-hardcoded-content */
import { useEffect } from 'react';
import { DeliveryStatus } from 'generated/graphql';
import { useQueryParams, useUpdateDeliveryStatusByMagicLink } from 'hooks';
import { Sentry } from 'lib/sentry';
import {
  DeliveryConfirmationError,
  DeliveryConfirmationLoading,
  DeliveryConfirmationPageWrapper,
  DeliveryConfirmed,
} from 'components';

export const ConfirmDelivery = () => {
  const [updateStatus, { loading, error }] =
    useUpdateDeliveryStatusByMagicLink();
  const { query } = useQueryParams();
  const magicLink = query.get('magicLink');

  const update = () => {
    if (magicLink) {
      updateStatus({
        variables: {
          magicLink,
          status: DeliveryStatus.PickedUp,
        },
      }).catch(() => {
        Sentry.captureMessage(
          `Error confirming delivery with magic link: ${magicLink}`,
          'error'
        );
      });
    }
  };

  useEffect(update, [magicLink, updateStatus]);

  return (
    <DeliveryConfirmationPageWrapper>
      {loading ? (
        <DeliveryConfirmationLoading />
      ) : error ? (
        <DeliveryConfirmationError onClick={update} />
      ) : (
        <DeliveryConfirmed />
      )}
    </DeliveryConfirmationPageWrapper>
  );
};
