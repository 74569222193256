import { gql } from '@apollo/client';
import { useAuthContext } from 'contexts';
import {
  DeliveriesOrderByField,
  Ordinality,
  useGetDeliveriesAndCountQuery,
} from 'generated/graphql';

export const DELIVERIES_QUERY = gql`
  query getDeliveriesAndCount(
    $orderBy: DeliveriesOrderBy
    $filters: DeliveryFilterOptions
    $countFilters: DeliveryFilterOptions
    $limit: Int
    $offset: Int
  ) {
    getDeliveries(
      orderBy: $orderBy
      filters: $filters
      limit: $limit
      offset: $offset
    ) {
      id
      additionalNotes
      carrier
      status
      createdAt
      lastNotifiedAt
      createdBy {
        id
        name
        avatar
      }
      pickedUpAt
      recipient {
        id
        name
        email
        user {
          id
          avatar
          name
          primaryEmail {
            email
          }
        }
      }
      deliveryZone {
        id
        name
        locationId
        levelId
        spaceId
      }
    }
    getDeliveriesCount(filters: $countFilters)
  }
`;

export const defaultOrderBy = {
  field: DeliveriesOrderByField.CreatedAt,
  ordinality: Ordinality.Desc,
};

export const useGetDeliveries = (
  orderBy = {},
  filterOptions = {},
  limit = 100,
  offset = 0
) => {
  const { currentOrg } = useAuthContext();

  // Order by can be undefined if the table is in the initial state. This allows us to
  // differentiate between the default table behavior and a deliberate sort in the same direction.
  if (Object.keys(orderBy).length === 0) {
    orderBy = defaultOrderBy;
  }

  const { data, loading, error, refetch } = useGetDeliveriesAndCountQuery({
    skip: !currentOrg,
    variables: {
      orderBy,
      filters: filterOptions,
      countFilters: filterOptions,
      limit,
      offset,
    },
    fetchPolicy: 'cache-and-network',
  });

  return {
    deliveries: data?.getDeliveries || [],
    deliveryCount: data?.getDeliveriesCount || 0,
    deliveriesLoading: loading,
    deliveriesError: error,
    refetchDeliveries: refetch,
  };
};
