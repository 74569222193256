/* eslint-disable no-useless-computed-key */
import {
  Button,
  Table,
  TableColumnsType,
  theme,
  Typography,
} from '@robinpowered/ui-kit';
import styled from '@emotion/styled';
import {
  useDeliveriesTableContext,
  useDeliveryZonesContext,
} from '../contexts';
import { useTranslation } from 'react-i18next';
import {
  DeliveriesOrderBy,
  DeliveriesOrderByField,
  DeliveryStatus,
  Ordinality,
} from 'generated/graphql';
import { Close, NotificationOutline, TrashOutline } from '@robinpowered/icons';

type SorterType = {
  column: Record<string, unknown>;
  columnKey: string;
  field: string;
  order: 'ascend' | 'descend';
};

type FilterItem = {
  id: string;
  name: string;
};

// Lets us display readable delivery status string names while using
// the value as an index for the correct generated types.
const deliveryStatusObject = {
  ['Pending']: DeliveryStatus.Pending,
  ['Picked Up']: DeliveryStatus.PickedUp,
  ['Unknown Recipient']: DeliveryStatus.UnknownRecipient,
  ['Wrong Recipient']: DeliveryStatus.WrongRecipient,
};

const getSortOrder = (
  orderOptions: DeliveriesOrderBy | undefined,
  field: DeliveriesOrderByField
) => {
  if (orderOptions?.field === field) {
    return orderOptions?.ordinality === 'ASC' ? 'ascend' : 'descend';
  }
  return undefined;
};

const formatFilterItems = (
  items: FilterItem[] | string[],
  toUpperCase?: boolean
) =>
  items.map((item) => ({
    text: toUpperCase
      ? (typeof item === 'string' ? item : item.name).toUpperCase()
      : typeof item === 'string'
      ? item
      : item.name,
    value: typeof item === 'string' ? item : item.id,
  }));

const checkFilter = (input: string, value: string) =>
  value.toLowerCase().includes(input.toLowerCase());

export const DeliveriesTable = () => {
  const { token } = theme.useToken();
  const {
    tableData,
    loading,
    modal,
    filterData: {
      deliveryIds,
      defaultDeliveryIds,
      recipients,
      defaultRecipients,
    },
    orderByOptions,
    selectedDeliveryIds,
    createDeleteModalConfig,
    createNotificationConfig,
    setFilterOptions,
    setOrderByOptions,
    setDeliveryIdSearchTerm,
    setRecipientSearchTerm,
    setSelectedDeliveryIds,
  } = useDeliveriesTableContext();
  const { deliveryZones } = useDeliveryZonesContext();
  const { t } = useTranslation('deliveriesTable');
  const deliveryIdFilters = formatFilterItems(deliveryIds, true);
  const defaultDeliveryIdFilters = formatFilterItems(defaultDeliveryIds, true);
  const filteredRecipients = recipients.filter(
    (recipient) => recipient !== null
  );
  const deliveryRecipientFilters = formatFilterItems(
    filteredRecipients as FilterItem[]
  );
  const filteredDefaultRecipients = defaultRecipients.filter(
    (defaultRecipient) => defaultRecipient !== null
  );
  const defaultDeliveryRecipientFilters = formatFilterItems(
    filteredDefaultRecipients as FilterItem[]
  );
  const deliveryZonesFilters = formatFilterItems(deliveryZones || []);
  const bannerText = `${selectedDeliveryIds.length} ${t('bulk_action_title')}`;

  // Note: at least one column should be left without a width to allow the table to remain fluid
  const columns: TableColumnsType = [
    {
      title: t('headers.id'),
      dataIndex: 'deliveryId',
      key: 'deliveryId',
      width: 200,
      filterSearch: (input, record) => {
        setDeliveryIdSearchTerm(input);
        return checkFilter(input, record.value as string);
      },
      // Note: This must be a non-empty array or the search field will not appear
      filters:
        deliveryIdFilters.length > 0
          ? deliveryIdFilters
          : defaultDeliveryIdFilters,
      // Reset the filters when the filter box closes
      onFilterDropdownOpenChange: (visible) => {
        if (!visible) {
          setDeliveryIdSearchTerm('');
        }
      },
    },
    {
      title: t('headers.recipient'),
      dataIndex: 'recipientName',
      key: 'recipientName',
      width: 240,
      sorter: true,
      sortOrder: getSortOrder(
        orderByOptions,
        DeliveriesOrderByField.RecipientName
      ),
      filterSearch: (input, record) => {
        setRecipientSearchTerm(input);
        return checkFilter(input, record.text as string);
      },
      filters:
        deliveryRecipientFilters?.length > 0
          ? deliveryRecipientFilters
          : defaultDeliveryRecipientFilters,
      // Reset the filters when the filter box closes
      onFilterDropdownOpenChange: (visible) => {
        if (!visible) {
          setRecipientSearchTerm('');
        }
      },
    },
    {
      title: t('headers.status'),
      dataIndex: 'status',
      key: 'status',
      width: 240,
      sorter: true,
      sortOrder: getSortOrder(orderByOptions, DeliveriesOrderByField.Status),
      filters: Object.keys(deliveryStatusObject).map((s) => ({
        text: s,
        value: s,
      })),
      onFilter: () => true,
      // Reset the filters when the filter box closes
      onFilterDropdownOpenChange: (visible) => {
        if (!visible) {
          setRecipientSearchTerm('');
        }
      },
    },
    {
      title: t('headers.zone'),
      dataIndex: 'deliveryZoneName',
      key: 'deliveryZoneName',
      width: 200,
      sorter: true,
      sortOrder: getSortOrder(
        orderByOptions,
        DeliveriesOrderByField.DeliveryZoneName
      ),
      filterSearch: true,
      filters: deliveryZonesFilters,
      onFilter: () => true,
    },
    {
      title: t('headers.last_notified'),
      dataIndex: 'lastNotifiedAt',
      key: 'lastNotifiedAt',
      width: 155,
      sorter: true,
      sortOrder: getSortOrder(
        orderByOptions,
        DeliveriesOrderByField.LastNotifiedAt
      ),
    },
    {
      title: t('headers.created_on'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 155,
      sorter: true,
      sortOrder: getSortOrder(orderByOptions, DeliveriesOrderByField.CreatedAt),
    },
    {
      title: t('headers.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: 155,
      fixed: 'right',
    },
  ];

  return (
    <StyledWrapper>
      {selectedDeliveryIds.length > 0 && (
        <BulkActionBanner>
          <StyledBannerButton
            icon={<Close color={token.colorTextLightSolid} size={16} />}
            onClick={() => setSelectedDeliveryIds([])}
            type="text"
          />
          <StyledBannerText>{bannerText}</StyledBannerText>
          <StyledBannerButton
            icon={<NotificationOutline color={token.colorText} size={14} />}
            onClick={async () => {
              await modal.warning(
                createNotificationConfig(selectedDeliveryIds)
              );
            }}
            type="default"
          />
          <StyledBannerButton
            icon={<TrashOutline color={token.colorText} size={16} />}
            onClick={async () => {
              await modal.error(createDeleteModalConfig(selectedDeliveryIds));
            }}
            type="default"
          />
        </BulkActionBanner>
      )}
      <Table
        dataSource={tableData}
        columns={columns}
        style={{ verticalAlign: 'middle' }}
        onChange={(pagination, filters, sorter) => {
          setFilterOptions({
            ids: filters.deliveryId as string[],
            recipientIds: filters.recipientName as string[],
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore:next-line
            statuses: filters.status?.map((s) => deliveryStatusObject[s]) || [],
            deliveryZoneIds: filters.deliveryZoneName as string[],
          });

          if ((sorter as SorterType).column) {
            setOrderByOptions({
              field: (sorter as SorterType).columnKey as DeliveriesOrderByField,
              ordinality:
                (sorter as SorterType).order === 'ascend'
                  ? Ordinality.Asc
                  : Ordinality.Desc,
            });
          } else {
            setOrderByOptions(undefined);
          }
        }}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedDeliveryIds,
          onChange: (selectedKeys) => {
            setSelectedDeliveryIds(selectedKeys as string[]);
          },
        }}
        pagination={false}
        loading={loading}
        scroll={{
          x: '100%',
        }}
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  td {
    vertical-align: middle;
  }
`;

const BulkActionBanner = styled.div`
  border-radius: 4px;
  background-color: var(--Colors-Brand-Info-colorInfo, #2774c1);
  align-items: center;
  display: flex;
  margin-bottom: 8px;
  padding: 8px;
`;

const StyledBannerButton = styled(Button)`
  margin-right: 8px;
`;

const StyledBannerText = styled(Typography.Text)`
  color: var(--Colors-Neutral-colorWhite, #fff);
  margin-right: 16px;
`;
