import { ButtonV4 } from '@robinpowered/design-system';
import { theme } from '@robinpowered/ui-kit';
import styled from '@emotion/styled';
import {
  EditAlt,
  NotificationOutline,
  TrashOutline,
} from '@robinpowered/icons';
import { useTranslation } from 'react-i18next';
import { createContext } from 'react';
import { useDeliveriesTableContext, useDeliveryEditContext } from '../contexts';
import { Delivery } from 'generated/graphql';

type TableActionButtonsProps = {
  delivery: Delivery;
};

const ReachableContext = createContext<string | null>(null);

export const TableActionButtons = ({ delivery }: TableActionButtonsProps) => {
  const { t } = useTranslation('deliveriesTable');
  const { setDeliveryToEdit } = useDeliveryEditContext();
  const {
    createDeleteModalConfig,
    createNotificationConfig,
    modal,
    selectedDeliveryIds,
  } = useDeliveriesTableContext();
  const { token } = theme.useToken();

  return (
    <ReachableContext.Provider value={''}>
      <Container>
        <span title={t(`buttons.edit`)}>
          <TransparentButton
            disabled={selectedDeliveryIds.length > 1}
            icon={() => (
              <EditAlt
                color={
                  selectedDeliveryIds.length > 1
                    ? token.colorTextDisabled
                    : token.colorText
                }
              />
            )}
            onClick={() => setDeliveryToEdit(delivery)}
          />
        </span>
        <span title={t(`buttons.notify`)}>
          <TransparentButton
            icon={() => <NotificationOutline color={token.colorText} />}
            onClick={async () => {
              await modal.warning(createNotificationConfig([delivery.id]));
            }}
          />
        </span>
        <span title={t(`buttons.delete`)}>
          <TransparentButton
            icon={() => <TrashOutline color={token.colorError} />}
            onClick={async () => {
              await modal.error(createDeleteModalConfig([delivery.id]));
            }}
          />
        </span>
      </Container>
    </ReachableContext.Provider>
  );
};

const TransparentButton = styled(ButtonV4)`
  background: transparent;
  margin: auto;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  svg {
    height: 16px;
    width: 16px;
  }
`;
