import { gql, useQuery } from '@apollo/client';
import { useAuthContext } from 'contexts';
import { GetDeliveriesPermittedLocationsQuery } from 'generated/graphql';

export const GET_DELIVERIES_PERMITTED_LOCATIONS = gql`
  query GetDeliveriesPermittedLocations {
    getDeliveriesPermittedLocations {
      canManage {
        id
        name
        levels {
          spaces {
            id
            name
          }
          name
          id
        }
      }
    }
  }
`;

export const useGetDeliveriesPermittedLocations = () => {
  const { currentOrg } = useAuthContext();

  const { data, loading } = useQuery<GetDeliveriesPermittedLocationsQuery>(
    GET_DELIVERIES_PERMITTED_LOCATIONS,
    {
      skip: !currentOrg,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-and-network',
    }
  );

  return {
    permittedLocations: data?.getDeliveriesPermittedLocations,
    permittedLocationsLoading: loading,
  };
};
