import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Checkbox,
  Modal,
  Row,
  Typography,
  message,
  theme,
} from '@robinpowered/ui-kit';
import {
  useDeleteDeliveries,
  useGetDeliveries,
  useGetDeliveryIds,
  useGetDeliveryRecipients,
  useNotifyDeliveryRecipients,
  useUpdateDelivery,
} from '../hooks';
import {
  DeliveryStatusSelectInline,
  Recipient,
  TableActionButtons,
} from '../components';
import moment from 'moment';
import { useDebounce } from 'react-use';
import {
  DeleteDeliveryZoneMutation,
  DeliveriesOrderBy,
  Delivery,
  DeliveryFilterOptions,
  DeliveryTableUpdateDeliveryMutation,
  Exact,
  Recipient as RecipientType,
  UpdateDeliveryInput,
} from 'generated/graphql';
import { useDeliveryFormContext } from './DeliveryFormContext';
import { useDeliveryDetailsContext } from './DeliveryDetailsContext';
import { ApolloError, MutationFunctionOptions } from '@apollo/client';
import { useAuthContext } from 'contexts';
import { removeDuplicates } from '../utils';
import { useTranslation } from 'react-i18next';
import { Sentry } from 'lib/sentry';
import { EmergencySolid } from '@robinpowered/icons';
import styled from '@emotion/styled';
import { useDeliveryEditContext } from './DeliveryEditContext';

const DEFAULT_ITEMS_PER_PAGE = 10;
const DEBOUNCE_DELAY_MS = 300;

type RenderedTableDataType = {
  key: string;
  deliveryId: JSX.Element;
  recipientName: JSX.Element;
  status: JSX.Element;
  deliveryZoneName: string;
  lastNotifiedAt: string;
  createdAt: string;
  actions: JSX.Element | null;
};

type FilterDataTypes = {
  deliveryIds: string[];
  defaultDeliveryIds: string[];
  recipients: (RecipientType | null)[];
  defaultRecipients: (RecipientType | null)[];
};

type DeliveriesTableContextValue = {
  tableData: RenderedTableDataType[];
  filterData: FilterDataTypes;
  filterOptions: DeliveryFilterOptions;
  currentPage: number;
  setCurrentPage: (pageNumber: number) => void;
  itemsPerPage: number;
  setItemsPerPage: (numItems: number) => void;
  totalItems: number;
  loading: boolean;
  orderByOptions: DeliveriesOrderBy | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modal: any; // Modal type is not exported from @robinpowered/ui-kit
  messageContextHolder: JSX.Element;
  modalContextHolder: JSX.Element;
  selectedDeliveryIds: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createDeleteModalConfig: (deliveryIds: string[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createNotificationConfig: (deliveryIds: string[]) => any;
  deleteDeliveries: (
    options?: MutationFunctionOptions<
      DeleteDeliveryZoneMutation,
      Exact<{ ids: string | string[] }>
    >
  ) => void;
  notifyRecipients: (
    options?: MutationFunctionOptions<
      DeleteDeliveryZoneMutation,
      Exact<{ deliveryIds: string | string[] }>
    >
  ) => void;
  setOrderByOptions: (options: DeliveriesOrderBy | undefined) => void;
  refetchDeliveries: () => void;
  setDeliveryIdSearchTerm: (term: string) => void;
  setRecipientSearchTerm: (term: string) => void;
  setFilterOptions: (options: DeliveryFilterOptions) => void;
  setSelectedDeliveryIds: (selectedDeliveryIds: string[]) => void;
  updateDelivery: (
    options?: MutationFunctionOptions<
      DeliveryTableUpdateDeliveryMutation,
      Exact<{ id: string; input: UpdateDeliveryInput }>
    >
  ) => void;
};

const DeliveriesTableContext = createContext<DeliveriesTableContextValue>({
  tableData: [],
  filterData: {
    deliveryIds: [],
    defaultDeliveryIds: [],
    recipients: [],
    defaultRecipients: [],
  },
  filterOptions: {},
  currentPage: 1,
  setCurrentPage: () => {},
  itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
  setItemsPerPage: () => {},
  totalItems: 0,
  loading: false,
  orderByOptions: {},
  modal: {},
  messageContextHolder: <></>,
  modalContextHolder: <></>,
  selectedDeliveryIds: [],
  createDeleteModalConfig: () => {},
  createNotificationConfig: () => {},
  deleteDeliveries: () => {},
  notifyRecipients: () => {},
  setOrderByOptions: () => {},
  refetchDeliveries: () => {},
  setDeliveryIdSearchTerm: () => {},
  setRecipientSearchTerm: () => {},
  setFilterOptions: () => {},
  setSelectedDeliveryIds: () => {},
  updateDelivery: () => {},
});

export const DeliveriesTableContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { t } = useTranslation('deliveriesTable');
  const { currentOrg } = useAuthContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE);
  const [deliveryIdSearchTerm, setDeliveryIdSearchTerm] = useState('');
  const [debouncedDeliveryIdSearchTerm, setDebouncedDeliveryIdSearchTerm] =
    useState('');
  const [recipientSearchTerm, setRecipientSearchTerm] = useState('');
  const [debouncedRecipientSearchTerm, setDebouncedRecipientSearchTerm] =
    useState('');
  const [filterOptions, setFilterOptions] = useState<DeliveryFilterOptions>({});
  const [orderByOptions, setOrderByOptions] = useState<
    DeliveriesOrderBy | undefined
  >(undefined);
  const [selectedDeliveryIds, setSelectedDeliveryIds] = useState<string[]>([]);
  const { deliveries, deliveryCount, deliveriesLoading, refetchDeliveries } =
    useGetDeliveries(
      orderByOptions,
      filterOptions,
      itemsPerPage,
      (currentPage - 1) * itemsPerPage
    );
  const { setSelectedDelivery } = useDeliveryDetailsContext();
  const { setDeliveryToEdit } = useDeliveryEditContext();
  const { deliveryCreationLoading } = useDeliveryFormContext();
  const [deleteDeliveries, { loading: isDeleting }] = useDeleteDeliveries();
  const [notifyRecipients, { loading: isNotifying }] =
    useNotifyDeliveryRecipients();
  const { deliveryIds } = useGetDeliveryIds(debouncedDeliveryIdSearchTerm);
  const { recipients } = useGetDeliveryRecipients(debouncedRecipientSearchTerm);
  const defaultDeliveryIds = deliveries.map((d) => d.id);
  const defaultRecipients = removeDuplicates(
    deliveries
      .filter((r) => r !== null)
      .map((d) => d.recipient) as RecipientType[]
  );
  const [updateDelivery, { loading: isUpdating }] = useUpdateDelivery();
  const { token } = theme.useToken();
  const [modal, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();
  // Using a local variable instead of state due to the modal context not causing a re-render
  let shouldNotifyRecipient = false;

  const handleDeleteDelivery = async (deliveryIds: string[]) => {
    const variables = {
      ids: deliveryIds,
      clientOptions: {
        notifyRecipient: shouldNotifyRecipient,
      },
    };
    const isMultiple = deliveryIds.length > 1;

    await deleteDeliveries({
      variables,
      onCompleted: async () => {
        Modal.destroyAll();
        setSelectedDelivery(null);
        setDeliveryToEdit(null);
        setSelectedDeliveryIds([]);
        await refetchDeliveries();
        await messageApi.success(
          t(
            isMultiple
              ? 'delete_dialog.success_multiple'
              : 'delete_dialog.success'
          )
        );
      },
      onError: async (error: ApolloError) => {
        Sentry.captureMessage(
          `Error deleting deliveries with IDs: ${deliveryIds}: ${error.message}`,
          'error'
        );
        await messageApi.error(
          t(isMultiple ? 'delete_dialog.error_multiple' : 'delete_dialog.error')
        );
      },
    });
  };

  const handleNotifyRecipient = async (deliveryIds: string[]) => {
    const variables = {
      deliveryIds: deliveryIds,
    };
    const isMultiple = deliveryIds.length > 1;

    await notifyRecipients({
      variables,
      onCompleted: async () => {
        Modal.destroyAll();
        setSelectedDeliveryIds([]);
        await refetchDeliveries();
        await messageApi.success(
          t(
            isMultiple
              ? 'resend_dialog.success_multiple'
              : 'resend_dialog.success'
          )
        );
      },
      onError: async (error: ApolloError) => {
        Sentry.captureMessage(
          `Error notifying recipients with delivery IDs: ${deliveryIds}: ${error.message}`,
          'error'
        );
        await messageApi.error(
          t(isMultiple ? 'resend_dialog.error_multiple' : 'resend_dialog.error')
        );
      },
    });
  };

  // Delete delivery modal configuration
  const createDeleteModalConfig = (deliveryIds: string[]) => ({
    maskClosable: true,
    icon: <EmergencySolid size={18} color={token.colorError} />,
    title: (
      <TitleWrapper>
        {t(
          deliveryIds.length > 1
            ? 'delete_dialog.title_multiple'
            : 'delete_dialog.title',
          { deliveryId: deliveryIds }
        )}
      </TitleWrapper>
    ),
    content: (
      <DeleteModalContent>{t('delete_dialog.content')}</DeleteModalContent>
    ),
    footer: (
      <ModalFooter>
        <Checkbox
          onChange={() => {
            shouldNotifyRecipient = !shouldNotifyRecipient;
          }}
        >
          {t('delete_dialog.notify')}
        </Checkbox>
        <div>
          <Button
            key="cancel"
            disabled={isDeleting}
            onClick={() => {
              Modal.destroyAll();
            }}
            style={{ marginRight: 8 }}
          >
            {t('delete_dialog.cancel')}
          </Button>
          <Button
            key="submit"
            danger
            type="primary"
            loading={isDeleting}
            onClick={() => handleDeleteDelivery(deliveryIds)}
          >
            {t('delete_dialog.confirm')}
          </Button>
        </div>
      </ModalFooter>
    ),
    width: 520,
  });

  // Notify recipient modal configuration
  const createNotificationConfig = (deliveryIds: string[]) => ({
    maskClosable: true,
    title: t(
      deliveryIds.length > 1
        ? 'resend_dialog.title_multiple'
        : 'resend_dialog.title',
      { deliveryId: deliveryIds }
    ),
    content: (
      <ResendModalContent>{t('resend_dialog.content')}</ResendModalContent>
    ),
    footer: (
      <ModalFooterEnd>
        <Button
          key="cancel"
          disabled={isNotifying}
          onClick={() => {
            Modal.destroyAll();
          }}
          style={{ marginRight: 8 }}
        >
          {t('resend_dialog.cancel')}
        </Button>
        <Button
          key="submit"
          type="primary"
          loading={isNotifying}
          onClick={() => handleNotifyRecipient(deliveryIds)}
        >
          {t('resend_dialog.confirm')}
        </Button>
      </ModalFooterEnd>
    ),
    width: 520,
  });

  useDebounce(
    () => {
      setDebouncedDeliveryIdSearchTerm(deliveryIdSearchTerm);
    },
    DEBOUNCE_DELAY_MS,
    [deliveryIdSearchTerm]
  );

  useDebounce(
    () => {
      setDebouncedRecipientSearchTerm(recipientSearchTerm);
    },
    DEBOUNCE_DELAY_MS,
    [recipientSearchTerm]
  );

  const renderedTableData = useMemo(() => {
    return deliveries.map(
      (delivery): RenderedTableDataType => ({
        key: delivery.id,
        deliveryId: (
          <Typography.Link
            onClick={() => setSelectedDelivery(delivery as Delivery)}
          >
            {delivery.id.slice(0, 8).toUpperCase()}
          </Typography.Link>
        ),
        recipientName: (
          <Recipient
            name={delivery.recipient?.name}
            avatar={delivery.recipient?.user?.avatar}
          />
        ),
        status: (
          <DeliveryStatusSelectInline
            id={delivery.id}
            status={delivery.status}
          />
        ),
        deliveryZoneName: delivery.deliveryZone.name,
        lastNotifiedAt: moment(delivery.lastNotifiedAt as string).format(
          'MMM Do'
        ),
        createdAt: moment(delivery.createdAt as string).format('MMM Do'),
        actions: <TableActionButtons delivery={delivery as Delivery} />,
      })
    );
  }, [deliveries, setSelectedDelivery]);

  return (
    <DeliveriesTableContext.Provider
      value={{
        tableData: renderedTableData,
        filterData: {
          deliveryIds:
            debouncedDeliveryIdSearchTerm.length > 0
              ? deliveryIds
              : defaultDeliveryIds,
          defaultDeliveryIds,
          recipients:
            debouncedRecipientSearchTerm.length > 0
              ? removeDuplicates(recipients as RecipientType[])
              : defaultRecipients,
          defaultRecipients,
        },
        filterOptions,
        currentPage,
        modal,
        messageContextHolder,
        modalContextHolder,
        setCurrentPage,
        itemsPerPage,
        setItemsPerPage,
        deleteDeliveries,
        notifyRecipients,
        selectedDeliveryIds,
        totalItems: deliveryCount,
        loading:
          !currentOrg ||
          deliveriesLoading ||
          deliveryCreationLoading ||
          isDeleting ||
          isUpdating ||
          isNotifying,
        orderByOptions,
        createDeleteModalConfig,
        createNotificationConfig,
        setOrderByOptions,
        refetchDeliveries,
        setDeliveryIdSearchTerm,
        setRecipientSearchTerm,
        setFilterOptions,
        setSelectedDeliveryIds,
        updateDelivery,
      }}
    >
      {children}
    </DeliveriesTableContext.Provider>
  );
};

export const useDeliveriesTableContext = (): DeliveriesTableContextValue => {
  return useContext(DeliveriesTableContext);
};

const TitleWrapper = styled(Row)`
  margin-left: 8px;
`;

const ResendModalContent = styled(Typography.Text)`
  display: block;
  margin-bottom: 12px;
`;

const DeleteModalContent = styled(Typography.Text)`
  display: block;
  padding-left: 8px;
  margin-bottom: 12px;
`;

const ModalFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
`;

const ModalFooterEnd = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 8px;
`;
