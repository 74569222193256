import { useState } from 'react';
import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { ChevronRightOutline } from '@robinpowered/icons';
import { Menu, Layout } from '@robinpowered/ui-kit';
import { useSidebarNavItems } from 'hooks';
import { useTranslation } from 'react-i18next';

export const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation('deliveriesPage');
  const { navItems, selectedKeys } = useSidebarNavItems();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <SideNavigationWrapper
      open={isOpen}
      onClick={!isOpen ? toggleSidebar : () => null}
    >
      <StyledSidebar>
        {isOpen && (
          <Menu
            items={navItems}
            mode="inline"
            selectedKeys={selectedKeys}
            defaultOpenKeys={[t('sidebar.configurations.title')]}
          />
        )}
      </StyledSidebar>
      <SidebarToggle
        onClick={toggleSidebar}
        className="sidebar-toggle"
        open={isOpen}
        onKeyPress={(e) => {
          if (e.key === 'Enter') toggleSidebar();
        }}
        tabIndex={0}
      >
        <ChevronRightOutline
          size={12}
          style={{
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.25s ease',
          }}
        />
      </SidebarToggle>
    </SideNavigationWrapper>
  );
};

const SidebarToggle = styled.div<{ open: boolean }>(
  (props) => `
    align-items: center;
    background-color: ${Colors.White0};
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 6px;
    border-radius: 100%;

    position: absolute;
    right: -10px;
    top: 10px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    visibility: ${props.open ? 'hidden' : 'visible'};
`
);

const SideNavigationWrapper = styled.div<{ open: boolean }>(
  (props) => `
    cursor: ${props.open ? 'default' : 'pointer'};
    width: ${props.open ? 250 : 20}px;
    height: 100%;
    transition: all 0.25s ease;
    position: relative;
    padding: var(--Space-Padding-padding, 16px);
    background-color: var(--Colors-Neutral-Bg-colorBgElevated, #FFF);

    &:hover {
        .sidebar-toggle {
            visibility: visible;
        }
    }
  `
);

const StyledSidebar = styled(Layout.Sider)`
  ul {
    border-right: none !important;
  }
`;
