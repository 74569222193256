import { gql, MutationTuple, useMutation } from '@apollo/client';
import {
  UpdateDeliveryZoneMutation,
  UpdateDeliveryZoneMutationVariables,
} from 'generated/graphql';

export const UPDATE_DELIVERY_ZONE = gql`
  mutation UpdateDeliveryZone($id: ID!, $input: UpdateDeliveryZoneInput!) {
    updateDeliveryZone(id: $id, input: $input) {
      id
    }
  }
`;

export const useUpdateDeliveryZone = (): MutationTuple<
  UpdateDeliveryZoneMutation,
  UpdateDeliveryZoneMutationVariables
> => {
  return useMutation<
    UpdateDeliveryZoneMutation,
    UpdateDeliveryZoneMutationVariables
  >(UPDATE_DELIVERY_ZONE);
};
